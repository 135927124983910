import React, { useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import OktaSignInWidget from '../components/auth/OktaSignInWidget';
import { useOktaAuth } from '@okta/okta-react';

const OktaLogin = ({ baseUrl }) => {
    const { authState, oktaAuth } = useOktaAuth();

    const onSuccess = useCallback((res) => {
        if (res.status === 'SUCCESS') {
            return oktaAuth.signInWithRedirect({
                sessionToken: res.session.token
            });
        } else {
            // The user can be in another authentication state that requires further action.
            // For more information about these states, see:
            //   https://github.com/okta/okta-signin-widget#rendereloptions-success-error
        }
    }, [oktaAuth])

    const onError = useCallback((err) => {
        console.log('error logging in', err);
    }, [])

    if (!authState) {
        return <div>Loading...</div>;
    } else {
        return authState.isAuthenticated ? <Navigate to={{ pathname: '/' }} /> :
            <div style={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                flexDirection: 'row',
                // alignItems: 'center',
                justifyContent: 'center'
            }}>
                <OktaSignInWidget
                    baseUrl={baseUrl}
                    onSuccess={onSuccess}
                    onError={onError}
                />
            </div>;
    }
}

export default OktaLogin