import { selector } from 'recoil';
import { sessionState, projectState } from './atoms';

export const dialSessions = selector({
    key: 'filteredSessionsDial',
    get: ({ get }) => {
        const currentSessions = get(sessionState)
        return currentSessions ? currentSessions.filter((item) => !!item.isDialSession) : undefined
    }
})

export const dialProjects = selector({
    key: 'filteredProjectsDial',
    get: ({ get }) => {
        const currentProjects = get(projectState)
        return currentProjects ? currentProjects.filter((item) => !!item.isDialProject) : undefined
    }
})
