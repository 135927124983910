import React, { useRef, useState, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { currentUser } from '../recoil/atoms'
import { dialProjects } from '../recoil/selectors'
import { useLocalStorage } from '../hooks/hooks'
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/material/styles';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import CloseIcon from '@mui/icons-material/Close';
import MaterialTable, { MTableBodyRow } from "@material-table/core"
// import ProjectTableDetails from '../components/ProjectTableDetails';
import ProjectsSubscription from '../recoil/ProjectsSubscription';
import { withSuspense } from '../hooks/suspense'
import DialModal from '../components/DialModal';
import { useOktaAuth } from '@okta/okta-react';
import { updateProjectPromise } from '../services/projectsService'

const PREFIX = 'DialProjectsPage';

const classes = {
  projects: `${PREFIX}-projects`,
  page: `${PREFIX}-page`
};

const Root = styled('div')({
  [`& .${classes.projects}`]: {
    marginBottom: '1rem',
    width: '100%'
  },
  padding: '1em'
});

const isEqual = require('lodash/isEqual');
const cloneDeep = require('lodash/cloneDeep');

const difference = (obj1, obj2) => {
  const keys = [...new Set([...Object.keys(obj1), ...Object.keys(obj2)])]
  const diff = keys.reduce((result, key) => {
    if (obj1.hasOwnProperty(key) && !obj2.hasOwnProperty(key)) {
      result[key] = cloneDeep(obj1[key])
    } else if (!obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
      result[key] = cloneDeep(obj2[key])
    } else if (!isEqual(obj1[key], obj2[key])) {
      result[key] = cloneDeep(obj2[key])
    }
    return result;
  }, {});

  return diff;
}

const allowedColumns = [
  'id', 'internalVideoName', 'videoMarkers', 'dialChartSegments', 'chartConfig'
]

const DialProjectsPage = React.memo(({ ...props }) => {

  const projects = useRecoilValue(dialProjects);
  const user = useRecoilValue(currentUser);
  const [projectSelected, setProjectSelected] = useLocalStorage('dialProjectSelected', null)//useState();
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState({});
  const [projectDialogOpen, setProjectDialogOpen] = useLocalStorage('dialProjectDialogState', false) //useState(false);
  // const [error, setError] = useState();
  // const [errorOpen, setErrorOpen] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  // const [tableFilters, setTableFilters] = useLocalStorage(`project${filter}tablefilter`, {})
  const [tableSort, setTableSort] = useLocalStorage(`dialprojectstablesort`, {})
  const { oktaAuth } = useOktaAuth();
  const navigate = useNavigate();

  const tableRef = useRef()

  // useEffect(() => {
  //   if (error) setErrorOpen(true)
  // }, [error])

  const dismissBtn = useCallback((id) => {
    return <>
      <IconButton
        aria-label="close"
        color="inherit"
        className={classes.close}
        onClick={() => closeSnackbar(id)}
      >
        <CloseIcon />
      </IconButton>
    </>
  }, []);

  // const handleErrorClose = useCallback(() => {
  //   setErrorOpen(false)
  // }, [])

  // const handleErrorClear = useCallback(() => {
  //   setError(undefined)
  // }, [])

  const showProjectGraph = useCallback((event, row) => {
    navigate(`/project/${row.id}`)
  }, [navigate])

  const submitProject = useCallback((event, submitData) => {
    setLoading(true)
    const data = difference(projectSelected, submitData)
    console.log('SUBMIT CHANGES:', data)
    if (Object.keys(data).length) {

      if (projectSelected) data.id = projectSelected.id
      if (data.videoMarkers) data.videoMarkers = JSON.stringify(data.videoMarkers)
      for (let i in data) {
        // if (typeof data[i] !== 'boolean' && !data[i]) delete data[i]
        if (!allowedColumns.includes(i) || (typeof data[i] !== 'boolean' && !data[i])) delete data[i]
      }

      if (data.tableData) delete data.tableData

      // FOR MYSQL DATA API
      if (data.chartConfig) data.chartConfig = JSON.stringify(data.chartConfig);
      if (data.dialChartSegments) data.dialChartSegments = JSON.stringify(data.dialChartSegments);
      if (data.internalVideoName) data.internalVideoName = JSON.stringify(data.internalVideoName);

      data.lastUpdatedBy = user.email
      const token = oktaAuth.getAccessToken()
      updateProjectPromise(data, token).then((res) => {
        setUpdate({})
        setLoading(false)
      }).catch(error => {
        if (error.message === 'Unauthorized') {
          oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
        } else {
          // setError('Error Saving Project')
          enqueueSnackbar('Error Saving Project', { preventDuplicate: true, action: dismissBtn });
          setUpdate({})
          setLoading(false)
        }
      })

    } else {
      setUpdate({})
      setLoading(false)
    }
    setProjectDialogOpen(false);
    setProjectSelected(null)
  }, [projectSelected, oktaAuth, setProjectDialogOpen, setProjectSelected, user.email]);

  const editProject = useCallback((event, data) => {
    setProjectSelected(data);
    setProjectDialogOpen(true);
  }, [setProjectSelected, setProjectDialogOpen])

  const handleCloseProjectDialog = useCallback(() => {
    setProjectDialogOpen(false);
    setProjectSelected(null)
  }, [setProjectSelected, setProjectDialogOpen]);

  return (
    <Root>
      <ProjectsSubscription refresh={update} key={`dialprojectssubscription`} />
      {projectDialogOpen && <DialModal forProject={true} hideDialSettings={true} team={user.team} data={projectSelected} onSubmit={submitProject} open={projectDialogOpen} handleClose={handleCloseProjectDialog} />}
      <div className={classes.projects}>
        <MaterialTable
          key={`dialprojectstable`}
          tableRef={tableRef}
          onOrderChange={(i, order) => setTableSort({
            [i]: order
          })}
          // onFilterChange={(e) => setTableFilters(prev => {
          //   return { ...prev, [filter]: e[0].value }
          // })}
          options={{
            emptyRowsWhenPaging: false,
            actionsColumnIndex: -1,
            // detailPanelType: 'single',
            pageSize: 20
          }}
          columns={[
            { title: "Project Name", field: "projectName" },
            { title: "Associated Sessions", field: "sessions", render: row => <div className={classes.names}>{JSON.parse(row.sessions).map(session => <span key={`${session.id}sessionnamerow`}>{session.sessionName}</span>)}</div> },
            { title: "Client", field: "client" },
            { title: "Video Platform", field: "videoPlatform" },
            {
              title: "Dial Project", field: "isDialProject",
              lookup: {
                'false': 'No',
                'true': 'Yes',
              },
            }
          ]}
          isLoading={loading || !projects}
          data={projects || []} //MATERIAL TABLE MUTATES PROPS :(
          title="Dial Projects"
          actions={[
            {
              icon: 'edit',
              tooltip: 'Edit',
              onClick: editProject,
            },
            (rowData) => {
              return {
                icon: ShowChartIcon,
                tooltip: 'View Graph',
                hidden: (!rowData.isDialProject),
                onClick: showProjectGraph,
                disabled: (!rowData.started)
              }
            }
          ]
          }
        />
      </div>
      {/* <Snackbar
        // key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        onExited={handleErrorClear}
        message={error}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              className={classes.close}
              onClick={handleErrorClose}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      /> */}
    </Root>
  );
})

export default withSuspense(DialProjectsPage)