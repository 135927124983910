import React, { useState, useRef, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import YouTube from 'react-youtube';
import { useEventListener } from './hooks/hooks'
import { makeStyles } from '@mui/material/styles';
const PREFIX = 'YoutubeVideoPlayer';

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  continue: `${PREFIX}-continue`,
  continueBack: `${PREFIX}-continueBack`,
  margin: `${PREFIX}-margin`,
  resume: `${PREFIX}-resume`,
  youtube: `${PREFIX}-youtube`,
  player: `${PREFIX}-player`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({aspectRatio}) => ({
  height: '100%',
  width: '100%',
  [`& .${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  [`& .${classes.card}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 2rem 2rem 2rem'
  },
  [`& .${classes.continue}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 2rem 1rem 2rem',
    zIndex: 1002,
    width: '35%',
    minWidth: '160px',
    maxWidth: '260px'
  },
  [`& .${classes.continueBack}`]: {
    zIndex: 1001,
    position: 'absolute',
    height: '100%',
    width: '100%',
    backdropFilter: 'blur(12px)'
  },
  [`& .${classes.margin}`]: {
    margin: '1rem'
  },
  [`& .${classes.resume}`]: {
    position: 'absolute',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    // backgroundColor: '#151515'
  },
  [`& .${classes.youtube}`]: {
    maxWidth: aspectRatio ? `${1 / aspectRatio * 100}vh` : 0,
    maxHeight: aspectRatio ? `${aspectRatio * 100}vw` : 0,
    width: aspectRatio ? '100%' : 0,
    height: aspectRatio ? '100%' : 0,
    flex: 1
  },
  [`& .${classes.player}`]: {
    width: '100%',
    height: '100%',
    pointerEvents: 'none'
  },
  [`& .${classes.player} iframe`]: {
    width: '100%',
    height: '100%',
  }
}));

// import * as workerTimers from 'worker-timers'

// document.pictureInPictureEnabled = false

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

const youtubeOpts = {
  playerVars: {
    autoplay: 0,
    autohide: 1,
    mute: 1,
    rel: 0,
    showinfo: 0,
    modestbranding: 1,
    loop: 0,
    fs: 0,
    cc_load_policy: 0,
    iv_load_policy: 3,
    controls: 0
  },
};

const getVideoAspectRatio = async (videoId) => {
  try {
    const res = await fetch(`https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoId}&format=json`)
    const { height, width } = await res.json()
    return height / width
  } catch (err) {
    console.log(err)
    return null
  }
}

const YoutubeVideoPlayer = React.memo(({ videoRef, style, fluid, fill, fullscreen, videoId, onStarted, onMetadataLoaded, onLoaded, onVideoLoaded, onReady, onPlay, onBuffer, onLoadingVideo, onVisiblityChange, onFinished, onVideoError, isLive, liveStreamStatus, sessionComplete, videoState, playbackStartTime, isMuted }) => {
  const [aspectRatio, setAspectRatio] = useState()
  const [loaded, setLoaded] = useState(false)
  // const [resuming, setResuming] = useState(alreadyStarted)

  // console.log('YOUTUBE PLAYER - RENDER')



  const visibilityChangeFn = () => { }

  const playerRef = useRef()
  // const loadedRef = useRef(false)
  const isPlaying = useRef(false)
  const stateSet = useRef(false)
  const hasStarted = useRef(false)
  const startTimeRef = useRef()
  const completeRef = useRef()
  // const timerRef = useRef()
  // const fadeRef = useRef()
  // const volumeRef = useRef(100)

  useEffect(() => {
    if ((document[hidden] || !document.hasFocus()) && onVisiblityChange) onVisiblityChange()
  }, [onVisiblityChange])

  useEffect(() => {
    startTimeRef.current = playbackStartTime
  }, [playbackStartTime])

  useEffect(() => {
    if (sessionComplete && playerRef.current) {
      console.log('ATTEMPTING TO STOP VIDEO')
      hasStarted.current = false
      playerRef.current.stopVideo()
    }
    completeRef.current = sessionComplete
  }, [sessionComplete])

  // if (onVisiblityChange) {
  useEventListener('blur', onVisiblityChange || visibilityChangeFn, true);
  useEventListener('focus', onVisiblityChange || visibilityChangeFn, true);
  useEventListener(visibilityChange, onVisiblityChange || visibilityChangeFn, true);
  // }

  useEffect(() => {
    if (loaded) {
      console.log('YOUTUBE VIDEO STATE', videoState)
      switch (videoState) {
        case 'playing':
          console.log('YOUTUBE VIDEO PLAYING')
          if (!isPlaying.current) {
            console.log('YOUTUBE STARTING PLAYBACK', hasStarted.current)
            // if (!hasStarted.current && !startTimeRef.current) hasStarted.current = true
            // if (!hasStarted.current) playerRef.current.seekTo(startTimeRef.current || 0)
            playerRef.current.playVideo()
          }
          break;
        case 'stopped':
          if (hasStarted.current) {
            playerRef.current.seekTo(startTimeRef.current || 0)
            playerRef.current.pauseVideo()
          } else if (!stateSet.current) {
            playerRef.current.stopVideo()
          }
          break;
        case 'paused':
          if (isPlaying.current) playerRef.current.pauseVideo()
          break;
        // case 'seeking':
        //   if (!hasStarted.current) playerRef.current.pauseVideo()
        //   break;
      }
      stateSet.current = true
    }
  }, [loaded, videoState])

  useEffect(() => {
    if (playerRef.current) {
      if (isMuted) {
        playerRef.current.mute()
      } else {
        playerRef.current.unMute()
      }
    }
  }, [isMuted])

  const afterLoaded = useCallback((event) => {
    playerRef.current = event.target
    console.log(playerRef.current)
    if (videoRef) videoRef.current = event.target //to get time data

    getVideoAspectRatio(videoId).then((ratio) => {
      setAspectRatio(ratio)
      if (onMetadataLoaded) onMetadataLoaded(ratio)
    })

    // GUEST PLAYER
    // if (isLive) {
    //   liveStreamStatus(videoId).then((status) => {
    //     console.log('live event copmleted?', !!status.actualEndTime)
    //     if (!!status.actualEndTime || sessionComplete) {
    //       if (onLoaded) onLoaded(playerRef.current.getDuration())
    //       if (onFinished) onFinished()
    //     } else {
    //       if (!sessionComplete) playerRef.current.playVideo()
    //     }
    //   })
    // } else if (!sessionComplete) {
    //   playerRef.current.playVideo()
    // } else {
    //   if (onLoaded) onLoaded(playerRef.current.getDuration())
    // }

    // ADMIN VIEWER
    if (!completeRef.current) {
      if (isLive) {
        liveStreamStatus(videoId).then((status) => {
          console.log('live event copmleted?', !!status.actualEndTime)
          if (!status.actualEndTime) {
            playerRef.current.playVideo()
          } else {
            if (onLoaded) onLoaded(playerRef.current.getDuration())
            if (onFinished) onFinished()
          }
        })
      } else {
        playerRef.current.playVideo()
      }
    } else {
      if (onLoaded) onLoaded(playerRef.current.getDuration())
    }

    playerRef.current.addEventListener('onStateChange', (event) => {
      isPlaying.current = (event.data === 1)
      if (onBuffer) onBuffer((event.data === 3 || event.data === -1 || (event.data === 1 && !hasStarted.current)))
      switch (event.data) {
        case 0:
          console.log('end event')
          if (onFinished) onFinished()
          break;
        case 1:
          console.log('play event', hasStarted.current)
          if (completeRef.current && !hasStarted.current) {
            console.log('STARTING AT', startTimeRef.current || 0)
            playerRef.current.seekTo(startTimeRef.current || 0, true)
          } else {
            if (onBuffer) onBuffer(false)
          }
          hasStarted.current = true
          if (onStarted) onStarted()
          if (onPlay) onPlay()
          break;
        case -1:
          console.log('unstarted...')
          // if (onLoadingVideo) onLoadingVideo()
          break;
        case 2:
          console.log('pause event')
          // if (!playerRef.current.ended()) 
          // playerRef.current.playVideo()
          break;
        case 3:
          console.log('buffering event')
          if (isLive && !completeRef.current) {
            liveStreamStatus(videoId).then((status) => {
              console.log('live event copmleted?', !!status.actualEndTime)
              if (!!status.actualEndTime && onFinished) onFinished()
            })
          }
          break;
        case 5:
          console.log('video cued...')
          break;
        default:
          console.log('state change event', event)
          break;
      }
    })

    playerRef.current.addEventListener('onError', (event) => {
      console.log('error event', event)
      if (onVideoError) onVideoError()
    })

    onReady && onReady()
    setLoaded(true)
  }, [videoId, isLive, liveStreamStatus, onBuffer, onLoaded, onMetadataLoaded, onPlay, onReady, onStarted, onFinished, onVideoError, videoRef])

  return (
    (<Root aspectRatio={aspectRatio}>
      <YouTube className={classes.player} containerClassName={classes.youtube} opts={youtubeOpts} videoId={videoId} onReady={afterLoaded} />
    </Root>)
  );
})

export default YoutubeVideoPlayer;
