import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Chip, Typography } from '@mui/material';
import ScheduleIcon from '@mui/icons-material/Schedule';
import VideocamIcon from '@mui/icons-material/Videocam';
import YouTubeIcon from '@mui/icons-material/YouTube';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import moment from 'moment'

const PREFIX = 'ClientPortalTableDetails';

const classes = {
    root: `${PREFIX}-root`,
    info: `${PREFIX}-info`,
    box: `${PREFIX}-box`,
    header: `${PREFIX}-header`,
    infogroup: `${PREFIX}-infogroup`,
    top: `${PREFIX}-top`,
    titlegroup: `${PREFIX}-titlegroup`,
    chip: `${PREFIX}-chip`,
    pfm: `${PREFIX}-pfm`,
    textIcon: `${PREFIX}-textIcon`,
    textRow: `${PREFIX}-textRow`,
    youtube: `${PREFIX}-youtube`
};

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    padding: '2em',
    [`& .${classes.info}`]: {
        // display: 'flex',
        // flexDirection: 'row',
        // justifyContent: 'space-evenly',
        width: '100%'
    },
    [`& .${classes.box}`]: {
        margin: '6px'
    },
    [`& .${classes.header}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '3px',
        borderBottom: '2px solid black'
    },
    [`& .${classes.infogroup}`]: {
        margin: '6px auto 6px 1em'
    },
    [`& .${classes.top}`]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline'
    },
    [`& .${classes.titlegroup}`]: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '8px'
    },
    [`& .${classes.chip}`]: {
        margin: '1px',
        fontSize: '0.6125rem'
    },
    [`& .${classes.pfm}`]: {
        margin: '1px',
        backgroundColor: '#6C54A4'
    },
    [`& .${classes.textIcon}`]: {
        marginRight: '0.5em'
    },
    [`& .${classes.textRow}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    [`& .${classes.youtube}`]: {
        margin: '1px',
        backgroundColor: '#e52d27'//'#b31217'
    }
});

const sessionTime = (time) => {
    const localTimezone = moment.tz.guess()
    return moment.tz(time, localTimezone).format('MMMM Do YYYY, h:mm a z')
}

const ClientPortalTableDetails = React.memo(({ id, sessionName, requireEmailValidation, isLiveSession, scheduledStartTime, staffArrivalTime, guestCheckInTime, team, client, isPfmScreening, pfmScreeningId, isYoutubeEvent, videoTitle, magicLinkUrl, magicLinkPasscode, magicLinkId, zoomMeetingId, zoomRegistrantId, getZoomDetails }) => {

    const [zoomInfo, setZoomInfo] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function getZoom() {
            setIsLoading(true);
            const data = await getZoomDetails(zoomMeetingId, zoomRegistrantId);
            setZoomInfo(data?.details);
            setIsLoading(false);
        }
        if (zoomMeetingId) getZoom();
    }, [])
    return (
        <Root>
            <div className={classes.top}>
                <div className={classes.titlegroup}>
                    <Typography variant="h4">{sessionName}</Typography>
                    <div className={classes.textRow}>
                        {isYoutubeEvent ? <YouTubeIcon className={classes.textIcon} /> : <VideocamIcon className={classes.textIcon} />}
                        <Typography variant="h6">{videoTitle}</Typography>
                    </div>
                    <div className={classes.textRow}>
                        <ScheduleIcon className={classes.textIcon} />
                        <Typography variant="h5">{sessionTime(scheduledStartTime)}</Typography>
                    </div>

                    <div className={classes.textRow}>
                        <Typography variant="overline">STAFF - {sessionTime(staffArrivalTime)}</Typography>
                        <Typography variant="h5">&nbsp;|&nbsp;</Typography>
                        <Typography variant="overline">GUESTS - {sessionTime(guestCheckInTime)}</Typography>
                    </div>
                </div>
                <div className={classes.titlegroup}>
                    {isPfmScreening && <Chip color="primary" className={classes.pfm} label={`PFM SCREENING #${pfmScreeningId}`} size="small"></Chip>}
                    {isYoutubeEvent && <Chip color="primary" className={classes.youtube} label={`YOUTUBE${isLiveSession && ' LIVE'}`} size="small"></Chip>}
                    <Chip className={classes.chip} label={`ID ${id} - ${team}`} size="small" variant="outlined"></Chip>
                    <Chip className={classes.chip} label={`${client}`} size="small" variant="outlined" color="secondary"></Chip>
                </div>
            </div>

            <div className={classes.info}>
                <div className={classes.box}>
                    <div className={classes.header}>
                        <OndemandVideoIcon fontSize="small" className={classes.textIcon} />
                        <Typography><b>Project Shared Link</b></Typography>
                    </div>
                    <div className={classes.infogroup}>
                        <Typography><b>Link:</b> {magicLinkUrl}</Typography>
                        {magicLinkPasscode && <Typography><b>Passcode:</b> {magicLinkPasscode}</Typography>}
                        <Typography><b>Email Verification:</b> {requireEmailValidation ? 'On' : 'Off'}</Typography>
                    </div>
                </div>
            </div>

            {zoomMeetingId ? <div className={classes.info}>
                <div className={classes.box}>
                    <div className={classes.header}>
                        <OndemandVideoIcon fontSize="small" className={classes.textIcon} />
                        <Typography><b>Zoom Meeting Details</b></Typography>
                    </div>
                    {!isLoading ? <div className={classes.infogroup}>
                        <Typography><b>Meeting Link:</b> {zoomInfo.join_url}</Typography>
                        {zoomInfo?.settings?.global_dial_in_numbers && zoomInfo?.settings?.global_dial_in_numbers?.length > 0 && <Typography>
                            <b>Phone Number:</b> {zoomInfo?.settings?.global_dial_in_numbers[0]?.number} </Typography>}
                        <Typography><b>Meeting ID:</b> {zoomInfo.id} </Typography>
                        {zoomInfo.password && <Typography> <b>Password:</b> {zoomInfo.password}</Typography>}
                    </div> : <Typography>Loading<span className="loading-ellipsis" /></Typography>}
                </div>
            </div> : <></>}
        </Root>
    );
})

export default ClientPortalTableDetails