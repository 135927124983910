import React, { useRef, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Collapse, Typography } from '@mui/material';
import { makeStyles } from '@mui/material/styles';
import SelectInputField from './SelectInputField'

const PREFIX = 'SegmentSelectorField';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  box: `${PREFIX}-box`,
  label: `${PREFIX}-label`
};

const Root = styled('div')({
  width: '100%',
  [`& .${classes.container}`]: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    padding: '6px',
    margin: '0 8px 0 8px',
    border: '2px solid #eee',
    borderRadius: '8px',
    transform: 'translate3d(0, 0, 0)'
  },
  [`& .${classes.box}`]: {
    display: 'flex',
    padding: '4px',
    alignItems: 'center',
  },
  [`& .${classes.label}`]: {
    textAlign: 'left',
    marginLeft: '10px !important'
  },
});

const SegmentSelectorField = React.memo(({ label, value, disabled, placeholder, onChange, options }) => {


  const selector = value || {}
  // console.log('segment selector', selector)
  const valueRef = useRef();
  valueRef.current = selector

  const filteredOptions = useMemo(() => {
    return (options || []).filter(x => !x.multiple)
  }, [options])

  const segmentOptions = useMemo(() => {
    return (value && options) ? (filteredOptions.find(x => x.questionKey === selector.segmentKey)?.matrixKeys || []) : []
  }, [value, filteredOptions])

  const segmentAnswers = useMemo(() => {
    return (value && options) ? (filteredOptions.find(x => x.questionKey === selector.segmentKey)?.answers || []) : []
  }, [value, filteredOptions])

  const handleKeyChange = useCallback((event, newValue) => {
    const newSelector = { ...valueRef.current }
    newSelector.segmentKey = newValue.questionKey
    newSelector.segmentOption = null
    newSelector.segmentValue = null
    onChange(event, newSelector)
  }, [onChange]);

  const handleOptionChange = useCallback((event, newValue) => {
    const newSelector = { ...valueRef.current }
    newSelector.segmentOption = newValue
    newSelector.segmentValue = null
    onChange(event, newSelector)
  }, [onChange]);

  const handleAnswerChange = useCallback((event, newValue) => {
    const newSelector = { ...valueRef.current }
    newSelector.segmentValue = newValue.answer
    onChange(event, newSelector)
  }, [onChange]);

  return (
    <Root>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <div className={classes.container}>
        <div className={classes.box}>
          <SelectInputField label="Segment Key" options={filteredOptions} optionLabel='questionKey' optionValueKey='questionKey' placeholder={placeholder || 'Select Segment Key'} disabled={disabled} value={selector.segmentKey} onChange={handleKeyChange} />
        </div>
        <Collapse in={!!selector.segmentKey}>
          <Collapse in={!!segmentOptions.length}>
            <div className={classes.box}>
              <SelectInputField label="Segment Option" options={segmentOptions} placeholder={placeholder || 'Select Segment Option'} disabled={disabled} value={selector.segmentOption} onChange={handleOptionChange} />
            </div>
          </Collapse>
          <div className={classes.box}>
            <SelectInputField label="Segment Answer" options={segmentAnswers} optionLabel='answer' optionValueKey='answer' placeholder={placeholder || 'Select Segment Answer'} disabled={disabled} value={selector.segmentValue} onChange={handleAnswerChange} />
          </div>
        </Collapse>
      </div>
    </Root>
  );
})

// SegmentSelectorField.defaultProps = {
//   label: ''
// }

export default SegmentSelectorField;
