import React, { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { makeStyles } from '@mui/material/styles';

const PREFIX = 'DateField';

const classes = {
  root: `${PREFIX}-root`,
  label: `${PREFIX}-label`,
  datePicker: `${PREFIX}-datePicker`
};

const Root = styled('div')({
  //border: 'solid 2px green',
  display: 'flex',
  padding: '5px',
  alignItems: 'center',
  width: '100%',
  [`& .${classes.label}`]: {
    flexBasis: '20%',
    // minWidth: '15%',
    textAlign: 'right',
    marginRight: '10px'
  },
  [`& .${classes.datePicker}`]: {
    flexBasis: '35%',
    // minWidth: '35%',
    //width: '100%'
  }
});

const DateField = React.memo(({ value, label, disabled, placeholder, fieldKey, required, conditional, conditionMet, onChange, invalidate, allowPast }) => {


  const handleChange = useCallback(newValue => {
    onChange(null, newValue)
  }, [onChange]);

  return (
    <Root>
      <Typography className={classes.label}>{label}</Typography>
      <DatePicker
        renderInput={props => <TextField {...props} disabled={disabled} helperText={null} variant="outlined" size="small" inputProps={{ ...props.inputProps, placeholder: placeholder || 'Enter Date/Time' }} />}
        // label=''
        // disableToolbar
        disablePast={!allowPast}
        // format="MM/DD/YY HH:mm:ss A"
        value={value}
        onChange={handleChange}
      />
    </Root>
  );
})

DateField.defaultProps = {
  label: 'Date'
}

export default DateField;
