
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/material/styles';
import { Modal, Fade, Backdrop, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Chip, Select, MenuItem, Checkbox, Button, Tooltip } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { getAllSessionFilesPromise, getSessionFilePromise } from './services/filesService';
import { FaDownload } from 'react-icons/fa';

const PREFIX = 'SessionFiles';

const classes = {
    modal: `${PREFIX}-modal`,
    paper: `${PREFIX}-paper`,
    table: `${PREFIX}-table`,
    header: `${PREFIX}-header`,
    headerText: `${PREFIX}-headerText`,
    tableCell: `${PREFIX}-tableCell`,
    buttonRow: `${PREFIX}-buttonRow`,
    button: `${PREFIX}-button`,
    downloadButton: `${PREFIX}-downloadButton`
};

const StyledModal = styled(Modal)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    [`& .${classes.paper}`]: {
        display: 'flex',
        flexDirection: 'column',
        outline: 'none',
        width: '80%',
        minWidth: '300px',
        // padding: '15px 0 0 0',
        padding: '1rem',
        maxHeight: '98%'
    },
    [`& .${classes.table}`]: {
        // margin: '1rem 0 1rem 0'
    },
    [`& .${classes.header}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
        padding: '0 15px 0 15px',
        marginBottom: '6px'
    },
    [`& .${classes.headerText}`]: {
        margin: 'auto 0 auto 0'
    },
    [`& .${classes.tableCell}`]: {
        fontSize: '2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 2rem 0 2rem'
    },
    [`& .${classes.buttonRow}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0.5rem'
    },
    [`& .${classes.button}`]: {
        margin: '0 0.25rem 0 0.25rem'
    },
    [`& .${classes.downloadButton}`]: {
        padding: '5px',
        minWidth: '0px',
    }
});


export const SessionFiles = React.memo(({ open, handleClose, sessionId, clientToken, cameFromClientPortal = false }) => {

    const [allFiles, setAllFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { oktaAuth } = useOktaAuth();

    const token = cameFromClientPortal ? clientToken : oktaAuth.getAccessToken();

    const checkAllFiles = async () => {
        setIsLoading(true);
        const data = await getAllSessionFiles();
        setAllFiles(data.map(i => {
            let key = i.Key.split('/').slice(2).join('/');
            let filename = i.Key.split('_').slice(2).join('_');
            return { key: key, name: filename }
        }));
        setIsLoading(false);
    }

    useEffect(() => {
        checkAllFiles();
    }, []);

    const getAllSessionFiles = async () => {
        return await getAllSessionFilesPromise(sessionId, token);
    }

    const getSessionFile = async (filename) => {
        const data = await getSessionFilePromise(sessionId, filename, token);
        return data;
    }

    const downloadFile = (url, file) => {
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': `application/octet-stream`,
            },
        }).then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.download = file.name;

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
    }

    const tableRef = useRef();

    return (
        <StyledModal
            open={open}
            onClose={handleClose}
            // closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 250,
                      sx: { zIndex: -1 }
                }
            }}
            disablePortal
        >
            <Fade in={open}>
                <Paper className={classes.paper}>
                    <div className={classes.header}>
                        <Typography variant="h5" className={classes.headerText}>Session Files</Typography>
                    </div>
                    <TableContainer className={classes.table} component={Paper}>
                        <Table size="small" ref={tableRef}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Filename</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isLoading ? <TableRow >
                                    <TableCell colSpan={2}>
                                        <Typography style={{ justifyContent: 'center' }} className="loading-text">Getting Files<span className="loading-ellipsis" /></Typography>
                                    </TableCell>
                                </TableRow> : allFiles.map(file => {
                                    return <TableRow>
                                        <TableCell >
                                            {file.name}
                                        </TableCell>
                                        <TableCell><Tooltip title="Download"><Button className={classes.downloadButton}><FaDownload onClick={async () => {
                                            const url = await getSessionFile(file.key);
                                            await downloadFile(url, file);
                                        }} /></Button></Tooltip></TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className={classes.buttonRow}>
                        {/* <Button color="primary" variant='contained' className={classes.button} onClick={handleClose} >CANCEL</Button> */}

                    </div>
                </Paper>
            </Fade>
        </StyledModal>
    );

})