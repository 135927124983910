import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import App from '../../App';

const SecureRoute = React.memo(({ ...props }) => {
    const { oktaAuth, authState } = useOktaAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const {pathname} = location;

    useEffect(() => {
        // Start login if and only if app has decided it is not logged inn
        if (authState && !authState.isAuthenticated && pathname !== '/login' && pathname !== '/authcallback') {
            // const originalUri  = useHref(pathname)
            // oktaAuth.signInWithRedirect({originalUri});
            navigate('/login')
        }

    }, [authState, oktaAuth, pathname, navigate]);

    if (!(authState && authState.isAuthenticated)) {
        return null;
    }

    return (
        <Routes>
        <Route
            {...props}
        />
        </Routes>
    );
});

export default SecureRoute;