import React, { useRef, useEffect, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import ReactPlayerLoader from '@brightcove/react-player-loader'
import 'videojs-overlay';
import { useEventListener } from './hooks/hooks'
// import { Card, Button, IconButton } from '@mui/material';
import { makeStyles } from '@mui/material/styles';
const PREFIX = 'BrightcoveVideoPlayer';

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  continue: `${PREFIX}-continue`,
  continueBack: `${PREFIX}-continueBack`,
  margin: `${PREFIX}-margin`,
  muteButton: `${PREFIX}-muteButton`,
  customOverlay: `${PREFIX}-customOverlay`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ wmOpacity }) => ({
  height: '100%',
  width: '100%',
  [`& .${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  [`& .${classes.card}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 2rem 2rem 2rem'
  },
  [`& .${classes.continue}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 2rem 1rem 2rem',
    zIndex: 1002,
  },
  [`& .${classes.continueBack}`]: {
    zIndex: 1001,
    position: 'absolute',
    height: '100%',
    width: '100%',
    backdropFilter: 'blur(12px)'
  },
  [`& .${classes.margin}`]: {
    margin: '1rem'
  },
  [`& .${classes.muteButton}`]: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    color: 'white'
  },
  [`& .${classes.customOverlay}`]: {
    // color: 'white !important',
    backgroundColor: 'transparent !important',
    height: `100% !important`,
    width: `100% !important`,
    marginTop: 'auto !important',
    marginBottom: 'auto !important',
    padding: '0 !important',
    left: '0 !important',
    marginLeft: '0 !important',
    // display: 'flex !important',
    display: 'block !important',
    // alignItems: 'center !important',
    // justifyContent: 'center !important',
    opacity: (wmOpacity && `${wmOpacity / 100} !important`) || '0.15 !important',
    // fontSize: 'calc(9px + (140 - 9) * ((100vw - 150px) / (2400 - 150))) !important',
    fontSize: '1em',
    userSelect: 'none !important',
    MozUserSelect: 'none !important',
    WebkitUserSelect: 'none !important',
    msUserSelect: 'none !important',
    // transform: 'translateY(-50%) rotate(-20.5deg) !important',
    // pointerEvents: 'none !important'
  }
}));

// import VolumeOffIcon from '@mui/icons-material/VolumeOffOutlined';
// import VolumeUpIcon from '@mui/icons-material/VolumeUpOutlined';

// document.pictureInPictureEnabled = false

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

const overlay = (content, height, width, seed) => {
  return `<svg style="object-fit: contain" height='100%' width='100%' viewBox='0 0 ${width} ${height}'>
    <svg height='100%' width='100%' viewBox='0 0 200 100'>    
      <filter id='filter' x='0%' y='0%' width='100%' height='100%'>
              <feTurbulence in="SourceGraphic" baseFrequency="10" octaves="10" type="fractalNoise" seed="${seed}" result="noise" />
              <feDisplacementMap in="SourceGraphic" in2="noise" scale="0.7" xChannelSelector="R" yChannelSelector="R" result="map" />
              <feGaussianBlur in="map" stdDeviation="${Math.floor(Math.random()) / 1000 + 0.15}" />
      </filter>
      <text x="50%" y="50%" transform="rotate(-${Math.atan(height / width) * 180 / Math.PI}, 100, 50)" filter="url(#filter)" textLength="200" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
    </svg>
  </svg>`
}

const tiledOverlay = (content, height, width, seed) => {
  return `<svg style="object-fit: contain" height='100%' width='100%' viewBox='0 0 ${width} ${height}'>
    <svg height='100%' width='100%'>
      <defs>
        <filter id='filter' x='0%' y='0%' width='100%' height='100%'>
          <feTurbulence in="SourceGraphic" baseFrequency="10" octaves="10" type="fractalNoise" seed="${seed}" result="noise" />
          <feDisplacementMap in="SourceGraphic" in2="noise" scale="0.7" xChannelSelector="R" yChannelSelector="R" result="map" />
          <feGaussianBlur in="map" stdDeviation="${height / 2000} ${width / 2000}" />
        </filter>
        <pattern id="p1" height='25%' width='50%' style="font-size: ${height / 22}px" patternUnits="userSpaceOnUse" patternTransform="rotate(-18.43494882292201)">    
          <text x="25%" y="12.5%" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="0" y="25%" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="50%" y="25%" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="0" y="0" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="50%" y="0" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
        </pattern>
      </defs>
      <rect x="0" y="0" width="100%" height="100%" fill="url(#p1)" filter="url(#filter)"></rect>
    </svg>
  </svg>`
}

const BrightcoveVideoPlayer = React.memo(({
  videoRef,
  style,
  fluid,
  fill,
  videoState,
  fullscreen,
  startVideo,
  wm,
  wmOpacity,
  watermarkStyle,
  playerId,
  videoId,
  multiSection,
  videoIndex = 0,
  alreadyStarted,
  sessionComplete,
  sessionStartTime,
  connectionOffset,
  videoDuration,
  videoStopTime,
  resumeTime,
  onStarted,
  onPlay,
  onBuffer,
  onTimeUpdate,
  onUnload,
  onMetadataLoaded,
  onVideoLoaded,
  onLoaded,
  onVisiblityChange,
  onReady,
  onFinished,
  onVideoError,
  isMuted
}) => {

  // const [resuming, setResuming] = useState(alreadyStarted)
  // const [muted, setMuted] = useState(true)
  // const [videoHeight, setVideoHeight] = useState()
  // const [videoWidth, setVideoWidth] = useState()



  const visibilityChangeFn = () => { }

  const playerRef = useRef()
  const indexRef = useRef()
  const loadedRef = useRef(false)
  const startedRef = useRef()
  startedRef.current = alreadyStarted
  const stateRef = useRef()
  stateRef.current = videoState
  const hasPlayed = useRef(false)

  useEffect(() => {
    if ((document[hidden] || !document.hasFocus()) && onVisiblityChange) onVisiblityChange()
  }, [onVisiblityChange])

  useEffect(() => {
    if (startVideo && playerRef.current) playerRef.current.play()
  }, [startVideo])

  useEventListener('blur', onVisiblityChange || visibilityChangeFn, true);
  useEventListener('focus', onVisiblityChange || visibilityChangeFn, true);
  useEventListener(visibilityChange, onVisiblityChange || visibilityChangeFn, true);

  const overlayFn = useMemo(() => {
    return watermarkStyle === 'Tiled' ? tiledOverlay : overlay
  }, [watermarkStyle])

  // const handleResume = useCallback(() => {
  //   if (videoTime && unloadTime) {
  //     playerRef.current.currentTime(videoTime + (Date.now() - unloadTime) / 1000)
  //   } else {
  //     playerRef.current.currentTime((Date.now() - sessionStartTime) / 1000)
  //   }
  //   playerRef.current.play()
  //   setResuming(false)
  // }, [])

  // useEffect(() => {
  //   if (sessionComplete || (videoTime && (videoTime + ((Date.now() - unloadTime) / 1000) >= videoDuration)) || ((sessionStartTime && (Date.now() - sessionStartTime) / 1000) >= videoDuration)) {
  //     if (playerRef.current) {
  //       playerRef.current.currentTime(videoDuration)
  //     } else if (onFinished) {
  //       onFinished()
  //     }
  //   }
  // }, [sessionComplete, videoTime, videoDuration, unloadTime])

  useEffect(() => {
    switch (videoState) {
      case 'playing':
        playerRef.current.play()
        break;
      case 'stopped':
        playerRef.current.currentTime(0)
      case 'paused':
        playerRef.current.pause()
        break;
      case 'seeking':
        if (!hasPlayed.current) {
          playerRef.current.play().then(() => playerRef.current.pause())
        } else {
          playerRef.current.pause()
        }
        break;
    }
  }, [videoState])

  useEffect(() => {
    console.log('VIDEO INDEX IS....', videoIndex)
    indexRef.current = videoIndex
    if (!!playerRef.current?.playlist()?.length) playerRef.current.playlist.currentItem(videoIndex)
  }, [videoIndex])

  const afterLoaded = useCallback((player) => {
    playerRef.current = player.ref
    console.log(playerRef.current)
    if (videoRef) videoRef.current = player.ref //to get time data

    playerRef.current.tech_.el_.disablePictureInPicture = true
    playerRef.current.tech_.el_.controls = false
    playerRef.current.tech_.el_.blur();
    playerRef.current.tech_.el_.addEventListener('focus', (e) => {
      e.currentTarget.blur()
      console.log('video focus')
    })

    playerRef.current.el_.blur();
    playerRef.current.el_.addEventListener('focus', (e) => {
      e.currentTarget.blur()
      console.log('brightcove focus')
    })

    // playerRef.current.preload('none')

    playerRef.current.controls(false);
    if (fullscreen) playerRef.current.enterFullWindow();
    playerRef.current.muted(true)
    playerRef.current.autoplay(false)

    //INITAL BLANK OVERLAy
    playerRef.current.overlay({
      content: '',
      class: classes.customOverlay,
      overlays: [{
        align: "center",
        content: "",
        start: 'play',
        end: 'ended'
      }]
    });

    // playerRef.current.overlay({
    //   content: '',
    //   class: classes.customOverlay,
    //   overlays: [{
    //     align: "center",
    //     content: overlayFn(wm, playerRef.current.tech_.el_.videoHeight, playerRef.current.tech_.el_.videoWidth, Math.floor(Math.random() * 10000000)),
    //     start: 'play',
    //     end: 'ended'
    //   }]
    // });

    // GUEST PLAYER
    // if (!alreadyStarted) {
    //   playerRef.current.play()
    // }

    // ADMIN VIEWER
    // if (alreadyStarted && !(sessionComplete || (videoTime && (videoTime + ((Date.now() - unloadTime) / 1000) >= videoDuration)) || ((sessionStartTime && (Date.now() - sessionStartTime) / 1000) >= videoDuration))) {
    //   const time = ((Date.now() - sessionStartTime) / 1000) - 10
    //   playerRef.current.currentTime(time >= 0 ? time : 0)
    //   // playerRef.current.play()
    // }

    // DIAL VIEWER
    if (alreadyStarted && !sessionComplete) {
      playerRef.current.preload('metadata')
      indexRef.current = videoIndex
      // console.log('RESUMING', videoIndex, resumeTime, playerRef.current.playlist())
      // if (!!playerRef.current?.playlist()?.length) playerRef.current.playlist.currentItem(videoIndex)
      // playerRef.current.currentTime(resumeTime)
      // playerRef.current.play()
    } else {
      playerRef.current.preload('auto')
    }

    playerRef.current.on('play', (event) => {
      console.log('play event')
      hasPlayed.current = true
      if (onStarted) onStarted()
    })

    playerRef.current.on('pause', () => {
      !(playerRef.current.ended() || ['paused', 'stopped', 'seeking'].includes(stateRef.current)) && playerRef.current.play()
      console.log('pause event')
    })

    playerRef.current.on('ended', () => {
      console.log('end event', indexRef.current)
      stateRef.current = 'stopped'
      playerRef.current.pause()
      if (onFinished) {
        onFinished((indexRef.current + 1) < (playerRef.current.playlist()?.length || 0) && (indexRef.current + 1))
      }
    })

    playerRef.current.on('timeupdate', (e) => {
      playerRef.current.overlay({
        content: '',
        class: classes.customOverlay,
        overlays: [{
          align: "center",
          content: overlayFn(wm, playerRef.current.tech_.el_.videoHeight, playerRef.current.tech_.el_.videoWidth, Math.floor(Math.random() * 10000000)),
          start: 'play',
          end: 'ended'
        }]
      });
      if (onTimeUpdate) onTimeUpdate(e)
    })

    playerRef.current.on('seeked', () => {
      console.log('seeked event')
      // if (videoTime && !loadedRef.current) {
      //   console.log('FAST forwarding')
      //   console.log(videoTime, unloadTime, videoTime + (Date.now() - unloadTime) / 1000)
      //   playerRef.current.currentTime(videoTime + (Date.now() - unloadTime) / 1000)
      //   loadedRef.current = true
      // }
    })

    playerRef.current.on('error', (e) => {
      console.log('error event', e)
      onVideoError && onVideoError()
    })

    playerRef.current.on('seeking', () => {
      console.log('seeking event')
    })
    playerRef.current.on('loadedmetadata', () => {
      console.log('loadedmetadata event', loadedRef.current)
      // DIAL VIEWER
      if (!loadedRef.current) {
        console.log('SETTING VIDEO INDEX', videoIndex, playerRef.current.playlist())
        if (!!playerRef.current?.playlist()?.length) playerRef.current.playlist.currentItem(indexRef.current)
        if (alreadyStarted && !sessionComplete) {
          console.log('RESUMING', resumeTime)
          playerRef.current.currentTime(resumeTime)
        }
      }
      loadedRef.current = true;
      playerRef.current.overlay({
        content: '',
        class: classes.customOverlay,
        overlays: [{
          align: "center",
          content: overlayFn(wm, playerRef.current.tech_.el_.videoHeight, playerRef.current.tech_.el_.videoWidth, Math.floor(Math.random() * 10000000)),
          start: 'play',
          end: 'ended'
        }]
      });
      if (onMetadataLoaded) onMetadataLoaded(playerRef.current.tech_.el_.videoHeight / playerRef.current.tech_.el_.videoWidth)
      // console.log('aspect ratio: ', playerRef.current.tech_.el_.videoHeight / playerRef.current.tech_.el_.videoWidth)
    })

    // playerRef.current.on('playerresize', () => {
    //   console.log('resize event')
    //   setVideoHeight(`${playerRef.current.currentHeight()}px`)
    //   setVideoWidth(`${playerRef.current.currentWidth()}px`)
    //   console.log(playerRef.current.currentHeight(), playerRef.current.currentWidth())
    // })

    playerRef.current.on('loadeddata', () => {
      console.log('loadeddata event')
      if (onVideoLoaded) onVideoLoaded()
    })
    playerRef.current.on('durationchange', (e) => {
      console.log('durationchange event', e)
      if (onLoaded) onLoaded(player.ref.duration())
    })
    playerRef.current.on('waiting', () => {
      console.log('waiting event')
      if (onBuffer) onBuffer()
    })
    // onLoaded && onLoaded(player.ref)
    onReady && onReady()
  }, [alreadyStarted, sessionComplete, videoIndex, fullscreen, onBuffer, onFinished, onLoaded, onMetadataLoaded, onReady, onStarted, onTimeUpdate, onVideoError, onVideoLoaded, resumeTime, classes.customOverlay, videoRef, wm, overlayFn])

  // const toggleMute = useCallback(() => {
  //   setMuted(prev => !prev)
  // }, [])

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.muted(isMuted)
    }
  }, [isMuted])

  return (
    (<Root wmOpacity={wmOpacity}>
      {/* {resuming && <>
        <div className={classes.continueBack}></div>
        <Card className={classes.continue} elevation={12}>
          Please click below to {videoTime && unloadTime ? 'resume' : 'join'} session
        <Button className={classes.margin} color='primary' variant='contained' onClick={handleResume}>{videoTime && unloadTime ? 'Resume' : 'Join'}</Button>
        </Card>
      </>} */}
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <ReactPlayerLoader attrs={{ style: { height: '100%', width: '100%' } }} options={{ fill: true }} accountId='6121265898001' playerId={playerId} videoId={!multiSection && videoId} playlistId={multiSection && videoId} onSuccess={afterLoaded} />
        {/* <IconButton className={classes.muteButton} color="inherit" onClick={toggleMute}>
          {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
        </IconButton> */}
      </div>
    </Root>)
  );
})

export default BrightcoveVideoPlayer;
