import React, { useCallback, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/material/styles';
import StandardField from './StandardField';
import { useMemoCompare } from '../../../hooks/hooks'

const PREFIX = 'MultiChatChannelField';

const classes = {
  root: `${PREFIX}-root`,
  dropzone: `${PREFIX}-dropzone`,
  addchannel: `${PREFIX}-addchannel`,
  channels: `${PREFIX}-channels`
};

const Root = styled('div')({
  width: '100%',
  // display: 'flex',
  // flexDirection: 'row',
  // padding: '6px',
  // margin: '0 8px 0 8px',
  [`& .${classes.dropzone}`]: {
    width: '95%',
    height: '6px',
    margin: '2px auto 2px auto',
    opacity: '0.3'
  },
  [`& .${classes.addchannel}`]: {
    margin: '8px 0 8px 0'
  },
  // label: {
  //   display: 'flex',
  //   justifyContent: 'flex-end',
  //   alignItems: 'center',
  //   flexBasis: '20%',
  //   // textAlign: 'right',
  //   marginRight: '10px !important',
  //   padding: '5px',
  // },
  [`& .${classes.channels}`]: {
    padding: '4px',
    width: '100%',
    borderLeft: '2px solid #eee'
  }
});

const isEqual = require('lodash/isEqual');
const cloneDeep = require('lodash/cloneDeep');

const MultiChatChannelField = React.memo(({ value = [], label, keyLabel, fieldKey, required, conditional, conditionMet, onChange, invalid, invalidate, ...restProps }) => {

  const valueRef = useRef();

  valueRef.current = value || []

  const channelNames = useMemoCompare(value ? value.map(x => x.displayName) : [], isEqual);

  const handleAddChatChannel = useCallback(() => {
    const newValue = [...valueRef.current, { type: 'group', staffAccess: ['session'], userAccess: [] }]
    onChange(null, newValue)
  }, [onChange])

  const handleChatChannelChange = useCallback((newValue, index) => {
    const newChatChannels = cloneDeep(valueRef.current)
    newChatChannels[index] = newValue
    onChange(null, newChatChannels)
  }, [onChange])

  const handleRemoveChatChannel = useCallback((index) => {
    const newChatChannels = cloneDeep(valueRef.current)
    newChatChannels.splice(index, 1)
    invalidate && invalidate(`${fieldKey}-button${index}`, false)
    onChange(null, newChatChannels)
  }, [onChange, invalidate, fieldKey])

  return (
    <Root>
      {label && <Typography className={classes.label}>{label}</Typography>}
      {value && value.map((channel, i) =>
        <React.Fragment key={`chatchannelfragment${i}`}>
          <StandardField
            type='chat-channel'
            fieldKey={`${fieldKey}-chat-channel${i}`}
            key={`${fieldKey}-chat-channel${i}`}
            index={i}
            channelNames={channelNames}
            value={channel}
            required={true}
            // required={required || (conditional && conditionMet)}
            onRemove={() => handleRemoveChatChannel(i)}
            onChange={(e, v) => handleChatChannelChange(v, i)}
            invalidate={invalidate}
            {...restProps}
          />
        </React.Fragment>
      )}
      <div className={classes.addchannel}>
        <Button variant="outlined" onClick={handleAddChatChannel}>
          <AddIcon /> Add Chat Channel
        </Button>
      </div>
    </Root>
  );
})

// MultiChatChannelField.defaultProps = {

// }

export default MultiChatChannelField;
