import React from 'react'
// import { Zoom, Grow, Slide } from '@mui/material';
import ErrorBoundary from '../recoil/ErrorBoundry'
import LoadingPage from '../pages/LoadingPage'

export const withSuspense = (WrappedComponent, ErrorComponent) => {

    const WrappedWithSuspense = props => {
        return (
            <React.Suspense fallback={<LoadingPage />}>
                <ErrorBoundary component={ErrorComponent && <ErrorComponent />}>
                    <WrappedComponent {...props} />
                </ErrorBoundary>
            </React.Suspense>
        );
    }

    return WrappedWithSuspense;
};