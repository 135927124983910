import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { currentUser } from './recoil/atoms'
// import { Zoom, Grow, Slide } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
// import { TransitionGroup, CSSTransition } from "react-transition-group";
import AppNavBar from './components/AppNavBar';
// \\import SettingsPage from './pages/SettingsPage';
import { ErrorPage } from './pages/ErrorPage';
import SessionDialPage from './SessionDialPage';
import DialSessionsPage from './pages/DialSessionsPage';
import LoadingPage from './pages/LoadingPage'
import config from './config.json'
import DialProjectsPage from './pages/DialProjectsPage';
const PREFIX = 'AppMain';

const classes = {
  app: `${PREFIX}-app`,
  transition: `${PREFIX}-transition`,
  body: `${PREFIX}-body`,
  footer: `${PREFIX}-footer`
};

const Root = styled('div')({
  height: '100%',
  width: '100%',
  minHeight: '100vh',
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  // overflow: 'hidden',
  [`& .${classes.transition}`]: {
    position: 'relative',
    width: 'auto',
    height: '100vh',
    overflow: 'hidden'
  },
  [`& .${classes.body}`]: {
    position: 'relative',
    width: 'auto',
    height: '100%',
    // padding: '1rem',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  [`& .${classes.footer}`]: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 'calc(2rem + 56px)',
  },
});

const AppMain = React.memo((props) => {
  console.log('render app main', props)

  const user = useRecoilValue(currentUser);

  console.log('user is: ', user)

  // useEffect(() => {
  //   if (!user) {
  //     props.history.push('/login')
  //   }
  // }, [user])

  useEffect(() => {
    if (!props.client && !user.accessLevels.includes('dial')) {
      window.location = config.smcUrl
    }
  }, [user, props.client])

  return (
    // <ThemeProvider theme={props.theme}>
    <Root>
      <AppNavBar />
      <div className={classes.body}>
        {/* <Zoom
          in={match != null}
          timeout={300}
          unmountOnExit> */}
        {/* <TransitionGroup>
          <CSSTransition
            key={props.location.pathname}
            timeout={{ enter: 300, exit: 0 }}
            // timeout={300}
            classNames="page"
            mountOnEnter
            unmountOnExit
          >
            <div className={classes.body}> */}
        <Routes>
          <Route path={'/session/:sessionId'} element={<SessionDialPage sessionId={props.sessionId} projectId={props.projectId} client={props.client} />} />
          <Route path={'/project/:projectId'} element={<SessionDialPage sessionId={props.sessionId} projectId={props.projectId} client={props.client} />} />
          {/* <Route path='/session/:sessionId/guests' Component={GuestsPage} /> */}
          {/* <Route path='/settings' element={<SettingsPage />} /> */}
          <Route path={'/'} Component={DialSessionsPage} />
          <Route path={'/sessions'} Component={DialSessionsPage} />
          <Route path='/projects' Component={DialProjectsPage} />
          <Route path='/authcallback' Component={LoadingPage} />
          <Route element={<ErrorPage error="Not Found" message="Page Not Found" />} />
        </Routes>
        {/* </div>
          </CSSTransition>
        </TransitionGroup> */}
        {/* </Zoom> */}
        {/* <div className={classes.footer} /> */}
      </div>
    </Root>
    // </ThemeProvider>
  );
})

export default AppMain;
