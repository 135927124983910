import React from 'react';
import { Card, Typography } from '@mui/material';

export const ErrorPage = ({ error, message }) => {

    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            // backgroundColor: 'rgba(0,0,0,0.6)'
        }}>
            <Card elevation={12} style={{
                padding: '1rem 2rem 2rem 2rem'
            }}>
                <Typography variant="h4">{error}</Typography>
                <Typography>{message}</Typography>
            </Card>
        </div>
    )
}