
import React from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/material/styles';
import { Modal, Fade, Backdrop, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Tab, Tabs } from '@mui/material';

const PREFIX = 'SegmentInfoPopup';

const classes = {
    modal: `${PREFIX}-modal`,
    paper: `${PREFIX}-paper`,
    header: `${PREFIX}-header`,
    infogroup: `${PREFIX}-infogroup`,
    headerText: `${PREFIX}-headerText`,
    marker: `${PREFIX}-marker`,
    sidemargin: `${PREFIX}-sidemargin`
};

const StyledModal = styled(Modal)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    maxWidth: "1200px",
    margin: "auto",
    [`& .${classes.paper}`]: {
        display: 'flex',
        flexDirection: 'column',
        outline: 'none',
        width: '50%',
        minWidth: '300px',
        // padding: '15px 0 0 0',
        padding: '1rem',
        // maxHeight: '98%'
        height: '90%',
        minHeight: '200px',
        maxHeight: '300px',
    },
    [`& .${classes.header}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
        padding: '0 15px 0 15px',
        marginBottom: '6px'
    },
    [`& .${classes.infogroup}`]: {
        margin: '6px auto 6px 1em',
        paddingTop: '10px'
    },
    [`& .${classes.headerText}`]: {
        margin: 'auto 0 auto 0'
    },
    [`& .${classes.marker}`]: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    [`& .${classes.sidemargin}`]: {
        margin: '0 3px 0 3px'
    },
});

export const SegmentInfoPopup = React.memo(({ id, handleClose, respondentData }) => {


    return (
        <StyledModal
            open={!!id}
            onClose={handleClose}
            // closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 250,
                    sx: { zIndex: -1 }
                }
            }}
            disablePortal
        >
            <Fade in={!!id}>
                <Paper className={classes.paper}>
                    <div className={classes.header}>
                        <Typography variant="h5" className={classes.headerText}>Guest Segment Answers</Typography>
                    </div>
                    <div className={classes.infogroup}>
                        {Object.entries(respondentData[id].segments).map(([question, answer], i) => (
                            <div className={classes.marker} key={`marker-display${i}`}>
                                <Typography variant="subtitle1">{question}</Typography>
                                <Typography className={classes.sidemargin} variant="subtitle1">&mdash;</Typography>
                                {(!!answer && typeof answer === 'object' && !Array.isArray(answer)) ? Object.entries(answer).map(([answerKey, answerValue]) => (<Typography variant="subtitle2">{answerKey} - {answerValue}</Typography>))
                                    : <Typography variant="subtitle2">{Array.isArray(answer) ? answer.join(', ') : answer}</Typography>}
                            </div>
                        ))}
                    </div>
                </Paper>
            </Fade>
        </StyledModal>
    );

})