
import React from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/material/styles';
import { Button } from '@mui/material';

const PREFIX = 'StatsLabelButton';

const classes = {
    buttonRoot: `${PREFIX}-buttonRoot`,
    buttonLabel: `${PREFIX}-buttonLabel`
};

const Root = styled('div')({
    [`& .${classes.buttonRoot}`]: {
        // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        fontSize: '60%',
        borderRadius: 6,
        width: '100%',
        // border: 0,
        // color: 'white',
        // height: 48,
        padding: '0px 16px',
        lineHeight: 1.25,
        // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
    [`& .${classes.buttonLabel}`]: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 0.5rem 0 0.5rem'
    }
});

export const StatsLabelButton = React.memo(({ dataKey, altLabel, onSelect, selected, activeColor, unselectedColor, textColor, disabled }) => {



    return (
        <Root>
            <Button variant="contained" disabled={disabled} onClick={onSelect} style={{ color: selected ? textColor : 'white', backgroundColor: selected ? activeColor : unselectedColor }} classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}>
                {altLabel || dataKey.map((k, i) =>
                    <span key={`datakey-${k}-${i}`}>{k}</span>
                )}
            </Button>
        </Root>
    );
})