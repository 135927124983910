import React, { useCallback, useRef, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/material/styles';
import StandardField from './StandardField';

const PREFIX = 'MultiTextInputField';

const classes = {
  root: `${PREFIX}-root`,
  label: `${PREFIX}-label`,
  builder: `${PREFIX}-builder`,
  addnew: `${PREFIX}-addnew`
};

const Root = styled('div')({
  //border: 'solid 2px green',
  display: 'flex',
  padding: '5px',
  alignItems: 'center',
  width: '100%',
  [`& .${classes.label}`]: {
    flexBasis: '20%',
    // minWidth: '25%',
    textAlign: 'right',
    marginRight: '10px'
  },
  [`& .${classes.builder}`]: {
    flexBasis: '80%'
  },
  [`& .${classes.addnew}`]: {
    margin: '8px 0 8px 0'
  },
});

const cloneDeep = require('lodash/cloneDeep');

const MultiTextInputField = React.memo(({ value = [], label, sublabel, staticLength, buttonLabel, fieldKey, required, conditional, conditionMet, onChange, invalid, invalidate, ...restProps }) => {

  const valueRef = useRef();

  valueRef.current = value || []

  const displayValue = useMemo(() => {
    if (staticLength) {
      const val = value || []
      const filler = Array(staticLength - val.length > 0 ? staticLength - val.length : 0).fill(null)
      return [...val, ...filler].slice(0, staticLength)
    } else {
      return value || []
    }
  }, [value, staticLength])

  const handleAdd = useCallback(() => {
    const newValue = [...valueRef.current, null]
    onChange(null, newValue)
  }, [onChange])

  const handleInputChange = useCallback((changedValue, index) => {
    const newValue = cloneDeep(valueRef.current)
    newValue[index] = changedValue
    onChange(null, newValue)
  }, [onChange]);

  const handleRemove = useCallback((index) => {
    const newValue = cloneDeep(valueRef.current)
    newValue.splice(index, 1)
    // invalidate && invalidate(`${fieldKey}-button${index}`, false)
    onChange(null, newValue)
  }, [onChange]);

  return (
    <Root>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <div className={classes.builder}>
        {displayValue && displayValue.map((val, i) =>
          <StandardField
            type='text'
            sublabel={typeof sublabel === 'function' ? sublabel(i + 1) : sublabel}
            fieldKey={`${fieldKey}-${i}`}
            key={`${fieldKey}-${i}`}
            value={val}
            required={required}
            // onRemove={() => handleRemove(i)}
            onChange={(e, v) => handleInputChange(v, i)}
            invalidate={invalidate}
            {...restProps}
          />
        )}
        <div className={classes.addnew}>
          {!staticLength && <Button variant="outlined" onClick={handleAdd}>
            <AddIcon /> {buttonLabel || 'Add'}
          </Button>}
        </div>
      </div>
    </Root>
  );
})

// MultiTextInputField.defaultProps = {

// }

export default MultiTextInputField;
