import React, { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, TextField } from '@mui/material';
import { makeStyles } from '@mui/material/styles';

const PREFIX = 'NumberInputField';

const classes = {
  root: `${PREFIX}-root`,
  label: `${PREFIX}-label`,
  textField: `${PREFIX}-textField`,
  text: `${PREFIX}-text`,
  inputRow: `${PREFIX}-inputRow`
};

const Root = styled('div')(({labelPosition, width}) => ({
  display: 'flex',
  flexDirection: labelPosition === 'top' ? 'column' : 'row',
  padding: '5px',
  alignItems: labelPosition === 'top' ? 'flex-start' : 'center',
  width: '100%',
  [`& .${classes.label}`]: {
    flexBasis: '20%',
    // minWidth: '15%',
    textAlign: labelPosition === 'top' ? 'left' : 'right',
    marginRight: '10px'
  },
  [`& .${classes.textField}`]: {
    // flexBasis: '80%'
    // width: '100%'
    width: `${width == 'full' ? '100%' : `calc(28px + (${width} * 1.25ch))`}`
  },
  [`& .${classes.text}`]: {
    padding: '3px'
  },
  [`& .${classes.inputRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexBasis: `${width == 'full' ? '100%' : 'auto'}`
  }
}));

let intg = /^\d*\.?\d*$/

const NumberInputField = React.memo(({ label, labelPosition, placeholder, placeholderChar, prefix, suffix, value, disabled, minValue, maxValue, decimals, onChange, invalid, invalidate, fullWidth }) => {
  const width = Math.ceil(Math.log10(Math.floor(maxValue) + 1))
  const char = placeholderChar || '#'
  const inputPlaceholder = placeholder || (char.repeat(width || 2) + (decimals ? `.${char.repeat(decimals)}` : ''))


  const handleChange = useCallback((event) => {
    if (intg.test(event.target.value) || event.target.value === '') {
      // const incomplete = event.target.value[event.target.value.length - 1] === '.'
      // const newValue = event.target.value !== '' ? incomplete ? event.target.value : parseFloat(event.target.value) : null
      const newValue = event.target.value !== '' ? event.target.value : null
      console.log('number change!', event.target.value, newValue)
      if (newValue === null || (event.target.value && (maxValue ? newValue <= maxValue : true))) {
        console.log('number on change!!', newValue)
        onChange(null, newValue)
      }
    }
  }, [maxValue, onChange]);

  const handleBlur = useCallback((event) => {
    if (event.target.value.length && decimals) {
      const splitValue = event.target.value.split('.')
      const newValue = splitValue[0] + '.' + (splitValue[1] || '').padEnd(2, '0')
      onChange(null, newValue)
    }
  }, [decimals, onChange])

  return (
    <Root width={width} labelPosition={labelPosition}>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <div className={classes.inputRow}>
        {!!prefix && <Typography className={classes.text}>{prefix}</Typography>}
        <TextField className={classes.textField} error={invalid} disabled={disabled} inputProps={{ maxLength: (width + ((decimals + 1) || 0)) || 524288, inputMode: decimals ? "decimal" : "numeric" }} variant="outlined" size="small" placeholder={inputPlaceholder} value={value !== null && value !== undefined ? value.toString() : ''} onChange={handleChange} onBlur={handleBlur} />
        {!!suffix && <Typography className={classes.text}>{suffix}</Typography>}
      </div>
    </Root>
  );
})

export default NumberInputField;
