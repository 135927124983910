import React, { useCallback, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/material/styles';
import StandardField from './StandardField';
import { useMemoCompare } from '../../../hooks/hooks'
import { useDrop } from 'react-dnd'

const PREFIX = 'MultiChartSegmentField';

const classes = {
  root: `${PREFIX}-root`,
  dropzone: `${PREFIX}-dropzone`,
  addbutton: `${PREFIX}-addbutton`
};

const Root = styled('div')({
  width: '100%',
  [`& .${classes.dropzone}`]: {
    width: '95%',
    height: '6px',
    margin: '2px auto 2px auto',
    opacity: '0.3'
  },
  [`& .${classes.addbutton}`]: {
    marginTop: '8px'
  }
});

const isEqual = require('lodash/isEqual');
const xor = require('lodash/xor');
const cloneDeep = require('lodash/cloneDeep');

const ChartSegmentDropTarget = React.memo(({ onDrop }) => {

  const [{ isOver }, drop] = useDrop({
    accept: 'question',
    drop: (item) => onDrop(item),
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  })
  return <div style={{ backgroundColor: isOver ? 'gold' : 'transparent' }} className={classes.dropzone} ref={drop} />;
})

const MultiChartSegmentField = React.memo(({ value, label, keyLabel, fieldKey, required, disabled, conditional, conditionMet, onChange, invalid, invalidate, options, virtualSegments, forChart, ...restProps }) => {

  const valueRef = useRef();
  const keyRef = useRef();

  valueRef.current = value || []

  const virtualSeg = useMemoCompare((forChart && virtualSegments && virtualSegments.length) ? virtualSegments.map(x => {
    x.virtual = true
    return x
  }) : [], isEqual);

  const segmentKeys = useMemoCompare(options ? options.concat(virtualSeg).map(x => {
    const answers = (x.answers || []).map(a => a.answer)
    const options = (x.matrixKeys || []).map(a => a.option)
    return { key: x.questionKey, virtual: x.virtual, predefined: x.predefined, multiple: x.multiple, matrix: x.matrix, freeNumeric: x.freeNumeric, answers: answers, options: options, dataKey: x.dataKey || 'segment', dataValue: x.dataValue, condition: x.condition }
  }) : [], isEqual);
  const segmentGroups = useMemoCompare(options ? options.concat(virtualSeg).reduce((r, v, i, a, k = v.questionKey) => (r[k] = v || [], r), {}) : {}, isEqual);
  const chartKeys = useMemoCompare(value ? value.map(x => {
    return { key: x.questionKey, virtual: x.virtual, predefined: x.predefined, dataKey: x.dataKey, dataValue: x.dataValue, condition: x.condition }
  }) : [], isEqual);

  useEffect(() => {
    if (!!forChart) {
      const newKeys = xor((keyRef.current || []).map(x => x.key), chartKeys.map(x => x.key))
      if (keyRef.current && newKeys.length) {
        const newSegments = cloneDeep(valueRef.current).map(x => {
          if (x.questionKey === null || x.questionKey === undefined || x.questionKey === '') {
            x.freeNumeric = false
            x.answers = []
            // x.virtual = false
            x.hasCrosstab = false
            x.crosstabKeys = []
            // x.dataKey = null
            x.dataValue = null
            // x.condition = null
          } else if (newKeys.includes(x.questionKey)) {
            if (segmentGroups[x.questionKey]) {
              const segflag = segmentGroups[x.questionKey].freeNumeric
              x.freeNumeric = segflag
              x.virtual = segmentGroups[x.questionKey].virtual
              x.predefined = segmentGroups[x.questionKey].predefined
              if (x.virtual) {
                x.hasCrosstab = false
                x.crosstabKeys = []
              }
              x.dataKey = segmentGroups[x.questionKey].dataKey
              x.dataValue = segmentGroups[x.questionKey].dataValue
              x.condition = segmentGroups[x.questionKey].condition
              x.answers = (segmentGroups[x.questionKey].answers ? segmentGroups[x.questionKey].answers : []).map(ans => {
                delete ans.goal
                // ans.showOnChart = true //segflag //['Male', 'Female'].includes(ans.answer)
                return {
                  ...ans,
                  showOnChart: true
                }
              })
            }
          }
          return x
        })
        onChange(null, newSegments)
      }
    }
    keyRef.current = chartKeys
  }, [chartKeys, segmentGroups, onChange, forChart])

  const handleAddSegment = useCallback(() => {
    const newSegment = {
      // question: '',
      hasCrosstab: false,
      crosstabKeys: [],
      // freeNumeric: false,
      answers: [{
        conditions: [{}],
        showOnChart: true
      }],
      dataKey: 'segments',
      virtual: !forChart
    }
    const newValue = [...valueRef.current, newSegment]
    onChange(null, newValue)
  }, [onChange, forChart])

  const handleExpandAll = useCallback(() => {
    let upd = valueRef.current.map(i => ({ ...i, collapsed: false }));
    onChange(null, upd)
  }, [onChange])

  const handleCollapseAll = useCallback(() => {
    let upd = valueRef.current.map(i => ({ ...i, collapsed: true }));
    onChange(null, upd)
  }, [onChange])

  const handleSegmentChange = useCallback((newValue, questionIndex) => {
    const newSegments = cloneDeep(valueRef.current)
    newSegments[questionIndex] = newValue
    onChange(null, newSegments)
  }, [onChange])

  const handleRemoveSegment = useCallback((index) => {
    const newSegments = cloneDeep(valueRef.current)
    newSegments.splice(index, 1)
    invalidate && invalidate(`${fieldKey}-chart-segment${index}`, false)
    onChange(null, newSegments)
  }, [onChange, invalidate, fieldKey])

  const handleReorderSegment = useCallback((item, index) => {
    const newSegments = cloneDeep(valueRef.current)
    const x = newSegments[item.index]
    newSegments.splice(item.index, 1)
    newSegments.splice(index, 0, x)
    onChange(null, newSegments)
  }, [onChange])

  return (
    <Root>
      {label && <Typography className={classes.label}>{label}</Typography>}
      {value && value.length > 0 && <><Button variant="outlined" onClick={handleExpandAll}>
        Expand All
      </Button>{'\u0020'}
        <Button variant="outlined" onClick={handleCollapseAll}>
          Collapse All
        </Button></>}
      {(value || []).map((segment, i) =>
        <React.Fragment key={`questionfragment${i}`}>
          {(!disabled && value.length > 1) && <ChartSegmentDropTarget key={`questiondrop${i}`} onDrop={(item) => handleReorderSegment(item, i)} />}
          {(disabled || value.length === 1) && <div className={classes.dropzone} />}
          <StandardField
            type='chart-segment'
            fieldKey={`${fieldKey}-chart-segment${i}`}
            key={`${fieldKey}-chart-segment${i}`}
            segmentKey={`${fieldKey}-chart-segment${i}`}
            segmentKeys={segmentKeys}
            segmentOptions={chartKeys}
            index={i}
            value={segment}
            required={true}
            forChart={forChart}
            virtual={!!(segment.virtual && segment.dataKey === 'segments')}
            predefined={!!(segment.virtual && segment.dataKey === 'segments' && segment.predefined)}
            // required={required || (conditional && conditionMet)}
            disabled={disabled}
            onRemove={() => handleRemoveSegment(i)}
            onChange={(e, v) => handleSegmentChange(v, i)}
            draggable={!disabled}
            invalidate={invalidate}
            label={keyLabel}
            {...restProps}
          />
          {(!disabled && (value.length > 1 && i === value.length - 1)) && <ChartSegmentDropTarget key={`segmentdrop${value.length}`} onDrop={(item) => handleReorderSegment(item, value.length)} />}
          {(disabled || value.length === 1) && <div className={classes.dropzone} />}
        </React.Fragment>
      )}
      <div className={classes.addbutton}>
        <Button variant="outlined" disabled={disabled || (!!forChart && segmentKeys.length === (value || []).length)} onClick={handleAddSegment}>
          <AddIcon /> Add {`${!!forChart ? 'Chart' : 'Virtual'}`} Segment
        </Button>
      </div>
    </Root>
  );
})

MultiChartSegmentField.defaultProps = {

}

export default MultiChartSegmentField;
