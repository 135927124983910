import { useEffect, useRef } from 'react';

export const useFieldValidator = (fieldType, value, { subkey, required, fieldKey, invalidate, conditional, conditionMet, regexp, minValue, maxValue, customValidator }) => {
    const isValid = useRef(true)
    useEffect(() => {
        // console.log('using validator hook', fieldType, fieldKey, value)
        let valid = true;
        if (required || (conditional && conditionMet)) {
            switch (fieldType) {
                // case 'question-builder':
                //     valid = value && !!value.length && value.reduce((pv, cv) => !!pv && !!(cv.questionKey && cv.answers && !!cv.answers.reduce((p, c) => !!p && !!(c && c.answer)), true), true)
                //     break;
                case 'chip':
                case 'marker-builder':
                    valid = value && !!value.length
                    break;
                case 'select':
                    valid = (value && !!value.length) || typeof value === 'number'
                    break;
                case 'question':
                    valid = !!(value && value.questionKey && value.answers && !!value.answers.reduce((p, c) => !!p && !!(c && c.answer), true) && !value.answers.map(x => x.answer).filter((s => v => s.has(v) || !s.add(v))(new Set())).length)
                    break;
                case 'chart-segment':
                    valid = !!value.questionKey && ((value.freeNumeric || (value.virtual && value.dataKey === 'segments')) ? !!(value.answers && !!value.answers.reduce((p, c) => !!p && !!(c && c.answer), true) && !value.answers.map(x => x.answer).filter((s => v => s.has(v) || !s.add(v))(new Set())).length) : true)
                    break;
                case 'marker':
                    valid = !!value.markerName && typeof value.markerHour === 'number' && typeof value.markerMinute === 'number' && typeof value.markerSecond === 'number'
                    break;
                // case 'hms-time':
                //     valid = !!value
                //     break;
                // case 'question':
                //     valid = value.question && !value.answers.includes(null) && !value.answers.includes(undefined) && !value.answers.includes('')
                //     break;
                case 'boolean':
                    valid = typeof value === 'boolean'
                    break;
                case 'minutes':
                    valid = Number.isInteger(value)
                    break;
                case 'hms-time':
                    valid = Number.isFinite(value)
                    if (minValue !== null && minValue !== undefined) valid = valid && value >= minValue
                    if (maxValue !== null && maxValue !== undefined) valid = valid && value <= maxValue
                    break;
                case 'number':
                    const num = parseFloat(value)
                    valid = Number.isInteger(num)
                    if (minValue !== null && minValue !== undefined) valid = valid && num >= minValue
                    if (maxValue !== null && maxValue !== undefined) valid = valid && num <= maxValue
                    break;
                case 'text':
                    valid = value ? (regexp ? regexp.test(value) : true) : false
                    break;
                case 'date':
                case 'time':
                case 'datetime':
                default:
                    valid = !!value
            }
        } else if (fieldType === 'text' && value && regexp) {
            valid = regexp.test(value)
        } else if ((fieldType === 'number') && value !== null && value !== undefined) {
            if (minValue !== null && minValue !== undefined) valid = valid && value >= minValue
            if (maxValue !== null && maxValue !== undefined) valid = valid && value <= maxValue
        }
        if ((required || (conditional && conditionMet)) && customValidator && typeof customValidator === 'function') {
            const custom = customValidator(value)
            Promise.resolve(typeof custom === 'function' ? custom() : custom).then((vld) => {
                valid = valid && vld
                if (valid !== isValid.current) {
                    isValid.current = valid
                    invalidate(subkey ? `${fieldKey}-${subkey}` : fieldKey, !valid)
                }
            })
        } else if (valid !== isValid.current) {
            isValid.current = valid
            invalidate(subkey ? `${fieldKey}-${subkey}` : fieldKey, !valid)
        }
    }, [fieldType, value, required, fieldKey, invalidate, conditional, conditionMet, regexp, minValue, maxValue, subkey, customValidator])
}