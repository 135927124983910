import React, { useRef, useEffect, useCallback, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/material/styles';
import MaterialTable from "@material-table/core"

const PREFIX = 'TransferListField';

const classes = {
  root: `${PREFIX}-root`,
  table: `${PREFIX}-table`,
  controls: `${PREFIX}-controls`,
  button: `${PREFIX}-button`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.root}`]: {
    //border: 'solid 2px green',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '1rem',
    boxSizing: 'border-box'
  },
  [`& .${classes.table}`]: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  [`& .${classes.controls}`]: {
    //border: 'solid 2px green',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '1rem'
    // width: '100%'
  },
  [`& .${classes.button}`]: {
    margin: '1em',
  },
});

const TransferListField = React.memo(({ value, sublabel, disabled, onChange, invalid, fullList, listKey }) => {

  const [selectedOut, setSelectedOut] = useState([])
  const [selectedIn, setSelectedIn] = useState([])

  const checkedRef = useRef([])

  useEffect(() => {
    console.log('SETTING CHECKED REF TO', [...selectedOut, ...selectedIn])
    checkedRef.current = [...selectedOut, ...selectedIn]
  }, [selectedOut, selectedIn])

  const handleSelectedOut = useCallback((rows) => {
    setSelectedOut(rows.map(x => x.chimeUserId))
  }, []);

  const handleSelectedIn = useCallback((rows) => {
    setSelectedIn(rows.map(x => x.chimeUserId))
  }, []);

  const handleMoveIn = useCallback((event) => {
    const newValue = [...value, ...selectedOut]
    checkedRef.current = [...selectedIn]
    setSelectedOut([])
    onChange(null, newValue)
  }, [value, selectedOut, selectedIn, onChange]);

  const handleMoveOut = useCallback((event) => {
    const newValue = [...value].filter(x => !selectedIn.includes(x))
    checkedRef.current = [...selectedOut]
    setSelectedIn([])
    onChange(null, newValue)
  }, [value, selectedOut, selectedIn, onChange]);

  const groupedList = useMemo(() => {
    console.log('GROUPING LIST...', checkedRef.current)
    return fullList.reduce((p, c) => {
      if (c.tableData) {
        console.log('TABLE DATA', c.tableData)
        c.tableData.checked = checkedRef.current.includes(c[listKey])
      }
      p[value.includes(c[listKey]) ? 'in' : 'out'].push(c)
      return p
    }, { in: [], out: [] })
  }, [fullList, listKey, value])

  console.log('TRANSFER LIST VALUE', value)

  return (
    (<Root>
      <div className={classes.root}>
        <div className={classes.table}>
          <Typography variant="h5">Available {sublabel}</Typography>
          <MaterialTable
            style={{ height: '100%' }}
            key='transfer-list-out'
            options={{
              emptyRowsWhenPaging: false,
              selection: true,
              showTextRowsSelected: false,
              pageSize: 5,
              paginationType: 'stepped',
              pageSizeOptions: []
            }}
            columns={[
              { title: "First Name", field: "firstName", filtering: false },
              { title: "Last Name", field: "lastName", filtering: false },
              // { title: "Email", field: "email", filtering: false },
              { title: "Zoom Alias", field: "alias", filtering: false }
            ]}
            data={groupedList.out || []} //MATERIAL TABLE MUTATES PROPS :(
            title={` `}
            onSelectionChange={handleSelectedOut}
          />
        </div>
        <div className={classes.controls}>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleMoveIn}
            disabled={!selectedOut.length}
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleMoveOut}
            disabled={!selectedIn.length}
          >
            ≪
          </Button>
        </div>
        <div className={classes.table}>
          <Typography variant="h5">Current {sublabel}</Typography>
          <MaterialTable
            style={{ height: '100%' }}
            key='transfer-list-in'
            options={{
              emptyRowsWhenPaging: false,
              selection: true,
              showTextRowsSelected: false,
              pageSize: 5,
              paginationType: 'stepped',
              pageSizeOptions: []
            }}
            columns={[
              { title: "First Name", field: "firstName", filtering: false },
              { title: "Last Name", field: "lastName", filtering: false },
              // { title: "Email", field: "email", filtering: false },
              { title: "Zoom Alias", field: "alias", filtering: false }
            ]}
            data={groupedList.in || []} //MATERIAL TABLE MUTATES PROPS :(
            title={` `}
            onSelectionChange={handleSelectedIn}
          />
        </div>
      </div>
    </Root>)
  );
})

TransferListField.defaultProps = {
}

export default TransferListField;
