import React, { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Chip } from '@mui/material';
import { makeStyles } from '@mui/material/styles';
// import ChipInput from 'material-ui-chip-input'
import { MuiChipsInput } from 'mui-chips-input'


const PREFIX = 'ChipInputField';

const classes = {
  root: `${PREFIX}-root`,
  subroot: `${PREFIX}-subroot`,
  label: `${PREFIX}-label`,
  sublabel: `${PREFIX}-sublabel`,
  chipField: `${PREFIX}-chipField`,
  chipRoot: `${PREFIX}-chipRoot`,
  chipChip: `${PREFIX}-chipChip`,
  chipInput: `${PREFIX}-chipInput`
};

const Root = styled('div')({
  [`& .${classes.root}`]: {
    //border: 'solid 2px green',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
    width: '100%'
  },
  [`& .${classes.subroot}`]: {
    //border: 'solid 2px green',
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  [`& .${classes.label}`]: {
    flexBasis: '20%',
    // minWidth: '15%',
    textAlign: 'right',
    marginRight: '10px'
  },
  [`& .${classes.sublabel}`]: {
    flexBasis: '100%',
    // minWidth: '15%',
    // textAlign: 'right',
    marginLeft: 'calc(20% + 16px)'
  },
  [`& .${classes.chipField}`]: {
    flexBasis: '80%'
    // width: '100%'
  },
  [`& .${classes.chipRoot}`]: {
    padding: '6px 0 0 6px !important'
  },
  [`& .${classes.chipChip}`]: {
    float: 'left',
    margin: '0 6px 6px 0'
  },
  [`& .${classes.chipInput}`]: {
    padding: '0 !important',
    margin: '0 6px 6px 0 !important'
  }
});

const ChipInputField = React.memo(({ value, label, disabled, placeholder, regexp, onChange, invalid, large, sublabel }) => {


  const handleValidate = useCallback((data) => {
    return regexp ? regexp.test(data) : true 
  }, [regexp])

  const handleAdd = useCallback((data) => {
    const newValue = [...(value || []), data]
    onChange(null, newValue)
  }, [value, onChange])

  const handleRemove = useCallback((data, index) => {
    const newValue = [...(value || [])]
    newValue.splice(index, 1)
    onChange(null, newValue)
  }, [value, onChange]);

  return (
    <Root>
      <div className={classes.root}>
        {label && <Typography className={classes.label}>{label}</Typography>}
        <MuiChipsInput className={classes.chipField}
          variant="outlined"
          size="small"
          classes={{
            inputRoot: classes.chipRoot,
            chip: classes.chipChip,
            input: classes.chipInput
          }}
          // margin="dense"
          renderChip={chipRenderer}
          // newChipKeyCodes={[13, 188]}
          // newChipKeys={['Enter', 'Comma']}
          error={invalid}
          disabled={disabled}
          value={value || []}
          placeholder={placeholder || 'Enter text'}
          alwaysShowPlaceholder={false}
          validate={handleValidate}
          onAddChip={handleAdd}
          onDeleteChip={handleRemove}
          multiline={large}
          rows={large ? 3 : 1}

        />
        {/* <ChipInput className={classes.chipField}
          variant="outlined"
          size="small"
          classes={{
            inputRoot: classes.chipRoot,
            chip: classes.chipChip,
            input: classes.chipInput
          }}
          margin="dense"
          chipRenderer={chipRenderer}
          newChipKeyCodes={[13, 188]}
          newChipKeys={['Enter', 'Comma']}
          error={invalid}
          disabled={disabled}
          value={value || []}
          placeholder={placeholder || 'Enter text'}
          alwaysShowPlaceholder={false}
          onBeforeAdd={handleValidate}
          onAdd={handleAdd}
          onDelete={handleRemove}
          multiline={large}
          rows={large ? 3 : 1} 
          /> */}
      </div>
      {sublabel && <div className={classes.subroot}>
        <Typography variant="caption" className={classes.sublabel}>{typeof sublabel === 'function' ? sublabel() : sublabel}</Typography>
      </div>}
    </Root>
  );
})

const chipRenderer = ({ value, text, isFocused, isDisabled, isReadOnly, handleClick, handleDelete, className }, key) => (
  <Chip
    key={key}
    className={className}
    size="small"
    style={{
      pointerEvents: isDisabled || isReadOnly ? 'none' : undefined,
      // backgroundColor: isFocused ? blue[300] : undefined
    }}
    onClick={handleClick}
    onDelete={handleDelete}
    label={text}
  />
)

export default ChipInputField;
