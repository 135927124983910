import React from 'react';
import AppRoutes from './AppRoutes';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { LocalizationProvider } from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const Root = () => {

    return (
        <RecoilRoot>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DndProvider backend={HTML5Backend}>
                    <Router>
                        <AppRoutes />
                    </Router>
                </DndProvider>
            </LocalizationProvider>
        </RecoilRoot>
    )
}

export default Root;