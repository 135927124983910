
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { useTheme, makeStyles } from '@mui/material/styles';
import { Button, Tooltip as ToolTip, Typography } from '@mui/material';
import { StatsLabelButton } from './StatsLabelButton';
import { Skeleton } from '@mui/material'
// import worker from 'workerize-loader?inline!./statsWorker' // eslint-disable-line import/no-webpack-loader-syntax
import { wrap } from './promise-worker'

const PREFIX = 'StatsHeader';

const classes = {
    flex: `${PREFIX}-flex`,
    options: `${PREFIX}-options`,
    slider: `${PREFIX}-slider`,
    chartStats: `${PREFIX}-chartStats`,
    skeleton: `${PREFIX}-skeleton`,
    statsBox: `${PREFIX}-statsBox`,
    statsText: `${PREFIX}-statsText`,
    statsSymbolBox: `${PREFIX}-statsSymbolBox`,
    statsTextBox: `${PREFIX}-statsTextBox`,
    statsTextRow: `${PREFIX}-statsTextRow`,
    stats: `${PREFIX}-stats`,
    label: `${PREFIX}-label`,
    selectInput: `${PREFIX}-selectInput`,
    selectIcon: `${PREFIX}-selectIcon`,
    selectMenu: `${PREFIX}-selectMenu`
};

const Root = styled('div')(({ fontSize }) => ({
    display: 'flex',
    flexDirection: 'column',
    [`& .${classes.options}`]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    [`& .${classes.slider}`]: {
        display: 'flex',
        alignItems: 'center'
    },
    [`& .${classes.chartStats}`]: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textAlign: 'center',
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
            height: '6px',
            backgroundColor: '#F5F5F5'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
            backgroundColor: '#F5F5F5',
            borderRadius: '6px'
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '6px',
            boxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
            backgroundColor: '#888'
        },
        scrollbarWidth: 'thin',
        scrollbarColor: '#888 #F5F5F5'
        // msOverflowStyle: 'auto',
        // position: 'absolute'
    },
    [`& .${classes.statsBox}`]: {
        boxSizing: 'border-box',
        padding: '0.75rem 0.75rem 0 0.75rem'
    },
    [`& .${classes.statsText}`]: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    [`& .${classes.statsSymbolBox}`]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    [`& .${classes.statsTextBox}`]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'baseline',
        padding: 5
    },
    [`& .${classes.statsTextRow}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    [`& .${classes.stats}`]: {
        fontSize: fontSize > 0 ? `${fontSize + 65}%` : '95%'
    },
    [`& .${classes.label}`]: {
        fontSize: fontSize > 0 ? `${fontSize - 10}%` : '40%',
        textTransform: 'uppercase',
        whiteSpace: 'nowrap'
    },
    [`& .${classes.selectInput}`]: {
        fontSize: fontSize > 0 ? `${fontSize + 10}%` : '60%',
        textTransform: 'uppercase',
        whiteSpace: 'nowrap'
    },
    [`& .${classes.selectIcon}`]: {
        // color: 'green'
    },
    [`& .${classes.selectMenu}`]: {
        "&:first-letter": {
            textTransform: "capitalize",
        }
    }
}));

let statsWorker;

function getContrastYIQ(hexcolor) {
    if (hexcolor === undefined) return hexcolor;
    hexcolor = hexcolor?.replace("#", "");
    var r = parseInt(hexcolor?.substr(0, 2), 16);
    var g = parseInt(hexcolor?.substr(2, 2), 16);
    var b = parseInt(hexcolor?.substr(4, 2), 16);
    var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? 'black' : 'white';
}

// const HeaderInputBase =  => ({
//     root: {
//         // 'label + &': {
//         //     marginTop: theme.spacing(3),
//         // },
//         fontSize: '2rem'
//     },
//     input: {
//         // display: 'inline-flex',
//         // alignItems: 'center',
//         //   borderRadius: 4,
//         //   position: 'relative',
//         //   backgroundColor: theme.palette.background.paper,
//         border: '1px solid #ced4da',
//         //   fontSize: 16,
//         //   padding: '10px 26px 10px 12px',
//         // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
//         // fontSize: '60%',
//         borderRadius: 6,
//         width: '100%',
//         minWidth: '64px',
//         // border: 0,
//         // color: 'white',
//         // height: 48,
//         padding: '0 0 0 10px',
//         lineHeight: 1.25,
//         // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
//         transition: theme.transitions.create(['background-color', 'border', 'box-shadow']),
//         fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
//         fontWeight: 500,
//         letterSpacing: '0.02857em',
//         textTransform: 'uppercase',
//         '&:focus': {
//             borderRadius: 4,
//             borderColor: '#80bdff',
//             boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
//         }
//     },
// }))(InputBase);

export const StatsHeader = React.memo(({ currentSegmentGroup, segmentsMap, singleView, loaded, chartData, averageFn, showAll, dialMode, dataKeys, virtualDataKeys, symbolKeys, buttons, scorePrecision, chartDomain, chartStart, chartEnd, segments, respondents, count, selected, onSelected, colors, getData, fontSize, customButtons, currentSegment, currentSymbol }) => {


    const [totals, setTotals] = useState({})

    const viewRef = useRef(singleView)
    // const currentRef = useRef()
    const firstRef = useRef()

    const domain = useMemo(() => {
        return chartDomain?.length ? chartDomain : [chartStart, chartEnd]
    }, [chartDomain, chartStart, chartEnd])

    useEffect(() => {
        const initWorkers = async () => {
            statsWorker = await wrap(new Worker(new URL('./statsWorker', import.meta.url)))
        }
        initWorkers()
        return () => {
            statsWorker?.terminate()
        }
    }, [])

    useEffect(() => {
        if (loaded) {
            if (singleView !== viewRef.current || firstRef.current) {
                onSelected(null, singleView ? [currentSegment] : dataKeys.filter(key => !!count[key] && (showAll || key !== 'all')), singleView ? symbolKeys.map(sym => sym.emoji) : [currentSymbol], true)
                viewRef.current = singleView
            }
            firstRef.current = true
        }
    }, [loaded, count, singleView, currentSegment, currentSymbol, currentSegmentGroup, dataKeys, symbolKeys])

    // useEffect(() => {
    //     statsWorker = worker()
    //     return () => {
    //         statsWorker.terminate()
    //     }
    // }, [])

    // const toggleView = useCallback(() => {
    //     setSingleView(prev => !prev)
    // }, [])

    // const changeCurrentSegment = useCallback((event) => {
    //     setCurrentSegment(event.target.value);
    //     setSelectedSegment(event.target.value);
    // }, [])

    // const changeCurrentSymbol = useCallback((event) => {
    //     setCurrentSymbol(event.target.value)
    // }, [])

    // const changeCurrentSegmentGroup = useCallback((event) => {
    //     setCurrentSegmentGroup(event.target.value)
    // }, [])

    useEffect(() => {
        if (averageFn && chartData.length) {
            // statsWorker.average(chartData, domain, dataKeys).then(setTotals)
            averageFn(chartData, domain, dataKeys)?.then(setTotals)
        }
    }, [chartData, averageFn, domain, dataKeys]);

    const currentButtons = useMemo(() => {
        if (domain?.length) {
            // console.log('BUTTONS', buttons)
            return Object.entries(buttons).reduce((p, [c, data]) => {
                // console.log('Z', c, data)
                p[c] = Object.keys(data).reduce((b, k) => {
                    b[k] = data[k].filter((dp, i) => {
                        //   return dp.videoTime <= time
                        return (dp.videoTime >= domain[0] && dp.videoTime <= domain[1])
                    })
                    return b
                }, {})
                return p
            }, {})
        } else {
            return buttons
        }
    }, [buttons, domain])

    const Stats = useMemo(() => {
        if (singleView) {
            const tuneOut = (currentButtons[currentSegment]?.['R'] && currentButtons[currentSegment]['R'].reduce((p, c) => p + c.total, 0)) || 0
            const allTuneOuts = (currentButtons['all']?.['R'] && currentButtons['all']['R'].reduce((p, c) => p + c.total, 0)) || 0
            const includeTuneOut = customButtons?.has('R')
            return (
                <GroupStats currentSegment={currentSegment} fontSize={fontSize} symbolKeys={symbolKeys} dataKey={currentSegment} colors={colors} unselectedColor={colors["unselected"]} defaultColor={colors["default"]} virtualKeys={virtualDataKeys} liveValue={chartData[chartData.length - 1]?.[currentSegment]} averageValue={totals[currentSegment]} total={count[currentSegment]} selected={selected[currentSegment]} onSelected={onSelected} scorePrecision={scorePrecision} customButtons={customButtons} tuneOut={tuneOut} allTuneOuts={allTuneOuts} includeTuneOut={includeTuneOut} dialMode={dialMode} />
            )
        } else {
            return dataKeys.filter(key => !!count[key] && (showAll || key !== 'all') && (dialMode !== 'symbol' || segmentsMap[currentSegmentGroup]?.includes(key))).map((key) => {
                return (
                    <SingleStats key={`SingleStats${key}`} currentSegment={currentSegment} chartData={chartData} fontSize={fontSize} dataKey={key} symbolKey={currentSymbol} activeColor={colors[key] || colors["default"]} textColor={getContrastYIQ(colors[key] || colors["default"])} unselectedColor={colors["unselected"]} virtual={virtualDataKeys[key]} total={count[key]} selected={selected[key]} onSelected={onSelected} liveValue={chartData[chartData.length - 1]?.[key]} averageValue={totals[key] && totals[key]} end={chartData[chartData.length - 1]?.end} scorePrecision={scorePrecision} buttons={currentButtons[key]} allButtons={currentButtons['all']} customButtons={customButtons} dialMode={dialMode} />
                )
            })
        }
    }, [chartData, singleView, segments, currentSegment, totals, currentButtons, currentButtons, currentButtons, count, selected, onSelected, colors, dialMode, dataKeys, symbolKeys, virtualDataKeys, segmentsMap, showAll, scorePrecision, fontSize])

    getData.current = useCallback(() => {
        return { totals, currentButtons, currentButtons, currentButtons, count, virtualDataKeys, liveChartData: chartData[chartData.length - 1] }
    }, [totals, currentButtons, currentButtons, currentButtons, count, virtualDataKeys, chartData])

    if (loaded && !!chartData.length) {
        return (
            <Root fontSize={fontSize}>
                {/* {dialMode === 'symbol' && <div className={classes.options}>
                    <div>
                        {singleView ? <Select value={currentSegment || ''} onChange={changeCurrentSegment} input={<HeaderInputBase />} {{ select: classes.selectInput, icon: classes.selectIcon }}>
                            {dataKeys.filter(key => !!count[key] && (showAll || key !== 'all')).map(key => <MenuItem {{ root: classes.selectMenu }} key={key} value={key}>{key === 'all' ? 'Total' : key}</MenuItem>)}
                        </Select> : <><Select value={currentSymbol || ''} onChange={changeCurrentSymbol} input={<HeaderInputBase />} classes={{ select: classes.selectInput, icon: classes.selectIcon }}>
                            {symbolKeys.map(({ emoji, tooltip }) => <MenuItem {{ root: classes.selectMenu }} key={emoji} value={emoji}>{emoji} - {tooltip}</MenuItem>)}
                        </Select><Select value={currentSegmentGroup || ''} onChange={changeCurrentSegmentGroup} input={<HeaderInputBase />} classes={{ select: classes.selectInput, icon: classes.selectIcon }}>
                                {Object.keys(segmentsMap).map((segment) => <MenuItem {{ root: classes.selectMenu }} key={segment} value={segment}>{segment === 'all' ? 'Total' : segment}</MenuItem>)}
                            </Select></>}
                    </div>
                    <div className={classes.slider}>
                        <GroupIcon fontSize='small' />
                        <Switch size="small" checked={singleView} onChange={toggleView} />
                        <MoodIcon fontSize='small' />
                    </div>
                </div>} */}
                <div className={classes.flex}>
                    <div className={classes.chartStats}>
                        {Stats}
                    </div>
                </div>
            </Root>
        );
    } else {
        return <Skeleton style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
        }} component='div' variant="rect" animation="wave" />
    }

})

const SingleStats = React.memo(({ currentSegment, chartData, dataKey, symbolKey, total, liveValue, averageValue, end, scorePrecision, selected, onSelected, virtual, buttons, allButtons, customButtons, activeColor, unselectedColor, textColor, fontSize, dialMode }) => {

    const theme = useTheme()



    const buttonTotals = useMemo(() => {
        return customButtons.keys().reduce((o, b) => {
            o[b] = {
                total: (buttons?.[b] && buttons[b].reduce((p, c) => p + c.total, 0)) || 0,
                all: (allButtons?.[b] && allButtons[b].reduce((p, c) => p + c.total, 0)) || 0
            }
            return o
        }, {})
    }, [customButtons, buttons, allButtons])

    const displayTotal = useMemo(() => {
        if (!!virtual && virtual.dataKey === 'button') {
            return virtual.condition === '!==' ? (total - buttonTotals?.[virtual.dataValue]?.all) : buttonTotals?.[virtual.dataValue]?.total
        } else {
            return total
        }
    }, [virtual, total, buttonTotals])

    const buttonText = useMemo(() => {
        if (customButtons.size) {
            return [...customButtons.entries().map(([btn, cfg]) => (<div key={`headerButtonText-${btn}`} style={{ lineHeight: '18px', fontSize: fontSize > 0 ? `${fontSize + 5}%` : '55%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', letterSpacing: '0.1rem', textTransform: 'uppercase', whiteSpace: 'nowrap' }}>
                {cfg.label + (cfg.multiPress ? cfg.plural || 's' : '')}&nbsp;<div style={{ color: cfg.totalColor || 'black', fontWeight: 'bold', fontSize: fontSize > 0 ? `${fontSize + 81.25}%` : '125%' }}>{buttonTotals?.[btn]?.total}</div>
                {cfg.showTotal && <div style={{ fontWeight: 'bold', fontSize: fontSize > 0 ? `${fontSize + 81.25}%` : '125%' }}>/{displayTotal}</div>}
            </div>))]
        } else {
            return [<div style={{ lineHeight: '18px', fontSize: fontSize > 0 ? `${fontSize + 5}%` : '55%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', letterSpacing: '0.1rem', textTransform: 'uppercase', whiteSpace: 'nowrap' }}>
                Viewers:&nbsp;<div style={{ color: theme.palette.tuneOut, fontWeight: 'bold', fontSize: fontSize > 0 ? `${fontSize + 81.25}%` : '125%' }}>{displayTotal}</div>
            </div>]
        }
    }, [customButtons, buttonTotals, displayTotal, theme])

    const displayValue = useMemo(() => symbolKey ? liveValue?.[symbolKey]?.total : end ? undefined : liveValue, [symbolKey, liveValue, end])

    const displayAverage = useMemo(() => symbolKey ? averageValue?.[symbolKey] : averageValue, [symbolKey, averageValue])

    // const isSelected = useMemo(() => symbolKeys ? symbolKeys.reduce((p, c) => p || selected[c], false) : selected, [symbolKeys, selected])

    // const onSelect = useCallback((e, symbol) => onSelected(e, [dataKey], symbol ? [symbol] : symbolKeys), [onSelected, dataKey, symbolKeys])

    const isSelected = useMemo(() => symbolKey ? selected?.[symbolKey] : selected, [symbolKey, selected])

    const onSelect = useCallback((e) => onSelected(e, [dataKey], [symbolKey]), [onSelected, dataKey, symbolKey])
    return (
        <div className={classes.statsBox}>
            <Typography variant="h5" className={classes.statsText}>
                <div>
                    <div className={classes.statsTextBox}>
                        {dialMode !== 'buttonOnly' && <>
                            <div className={classes.statsTextRow}>
                                {symbolKey ? <div className={classes.stats}>{displayValue}</div> : <div className={classes.stats}>{(!isNaN(displayValue) && displayValue !== null) ? (Math.round((displayValue * Math.pow(10, scorePrecision)).toFixed(scorePrecision)) / Math.pow(10, scorePrecision)) : '\u2012'}</div>}
                                <div className={classes.label}>{symbolKey ? '# OF CLICKS' : 'CURRENT'}</div>
                            </div>
                            {dialMode === 'score' && <>&nbsp;|&nbsp;</>}
                        </>}
                        {dialMode === 'score' && <div className={classes.statsTextRow} >
                            <div className={classes.stats}>{(!isNaN(displayAverage) && displayAverage !== null) ? (Math.round((displayAverage * Math.pow(10, scorePrecision)).toFixed(scorePrecision)) / Math.pow(10, scorePrecision)) : '\u2012'}</div>
                            <div className={classes.label}>AVERAGE</div>
                        </div>}
                        {/* &nbsp;|&nbsp; 
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div style={{ color: theme.palette.tuneOut }}>{tuneOut}</div>
                            <div style={{ fontSize: '40%', textTransform: 'uppercase', whiteSpace: 'nowrap' }}>TUNE OUT</div>
                        </div> */}
                    </div>
                    <ToolTip placement="bottom" title={
                        <div style={{ fontSize: fontSize > 0 ? `${fontSize + 5}%` : '55%', textAlign: 'center' }}>
                            {(displayTotal ? (Math.round(((buttonTotals?.['R']?.total / displayTotal * 100) * 100).toFixed(2)) / 100) : '\u2012')}% TUNE OUT
                        </div>
                    }>
                        <>{buttonText}</>
                    </ToolTip>
                    {/* {symbolKeys && <ToggleButtonGroup variant="text" color="default">
                        {symbolKeys.map(symbol => <ToggleButton style={{ color: selected[symbol] ? 'initial' : 'auto' }} onClick={(e) => onSelect(e, symbol)}>{symbol}</ToggleButton>)}
                    </ToggleButtonGroup>} */}
                    <StatsLabelButton dataKey={dataKey === 'all' ? ['Total'] : dataKey.split(" + ")} selected={isSelected} onSelect={onSelect} activeColor={activeColor} unselectedColor={unselectedColor} textColor={textColor} />
                    {/* <div style={{ borderRadius: '6px', color: 'white', backgroundColor: theme.palette[dataKey] || theme.palette.primary.main, fontSize: '60%', textTransform: 'uppercase' }}>{dataKey}</div> */}
                </div>
            </Typography>
        </div>
    )
})

const GroupStats = React.memo(({ symbolKeys, dataKey, total, liveValue, averageValue, scorePrecision, selected, onSelected, virtual, colors, unselectedColor, defaultColor, fontSize, tuneOut, allTuneOuts, includeTuneOut, dialMode }) => {

    const theme = useTheme()



    const displayTotal = useMemo(() => {
        if (!!virtual && virtual.dataKey === 'button' && virtual.dataValue === 'R') {
            return virtual.condition === '!==' ? (total - allTuneOuts) : tuneOut
        } else {
            return total
        }
    }, [virtual, total, tuneOut, allTuneOuts])

    const onSelect = useCallback((e, symbolKey) => onSelected(e, [dataKey], symbolKey ? [symbolKey] : symbolKeys.map(sym => sym.emoji)), [onSelected, dataKey, symbolKeys])

    const tuneOutColor = useMemo(() => getContrastYIQ(colors['tuneout'] || defaultColor), [colors, defaultColor])

    return (<>
        {symbolKeys.map((symbol) => {
            const activeColor = colors?.[symbol.emoji] || defaultColor
            const textColor = getContrastYIQ(activeColor)
            const isSelected = selected?.[symbol.emoji]
            const displayValue = liveValue?.[symbol.emoji]?.total
            const displayAverage = averageValue?.[symbol.emoji]
            return (
                <div className={classes.statsBox}>
                    <Typography variant="h5" className={classes.statsText}>
                        <div>
                            <div className={classes.statsTextBox}>
                                <>
                                    <div className={classes.statsTextRow}>
                                        <div className={classes.stats}>{displayValue}</div>
                                        <div className={classes.label}># OF CLICKS</div>
                                    </div>
                                    {/* &nbsp;|&nbsp; */}
                                </>
                                {/* <div className={classes.statsTextRow}>
                                    <div className={classes.stats}>{(!isNaN(displayAverage) && displayAverage !== null) ? (Math.round((displayAverage * Math.pow(10, scorePrecision)).toFixed(scorePrecision)) / Math.pow(10, scorePrecision)) : '\u2012'}</div>
                                    <div className={classes.stats}>{'\u2012'}</div>
                                    <div className={classes.label}>AVERAGE</div>
                                </div> */}
                            </div>
                            <div style={{ lineHeight: '18px', fontSize: fontSize > 0 ? `${fontSize + 50}%` : '100%', fontFamily: 'Noto Color Emoji' }}>{symbol.emoji}</div>
                            <StatsLabelButton dataKey={[symbol.tooltip]} selected={isSelected} onSelect={(e) => onSelect(e, symbol.emoji)} activeColor={activeColor} unselectedColor={unselectedColor} textColor={textColor} />
                        </div>
                    </Typography>
                </div>
            )
        })}
        {(dialMode === 'score' || includeTuneOut) && <div className={classes.statsBox}>
            <Typography variant="h5" className={classes.statsText}>
                <div>
                    <div className={classes.statsTextBox} style={dialMode === 'symbol' ? { visibility: 'hidden' } : {}}>
                        <>
                            <div className={classes.statsTextRow}>
                                <div className={classes.stats}>{(!isNaN(liveValue) && liveValue !== null) ? (Math.round((liveValue * Math.pow(10, scorePrecision)).toFixed(scorePrecision)) / Math.pow(10, scorePrecision)) : '\u2012'}</div>
                                <div className={classes.label}>CURRENT</div>
                            </div>
                            &nbsp;|&nbsp;
                        </>
                        <div className={classes.statsTextRow}>
                            <div className={classes.stats}>{(!isNaN(averageValue) && averageValue !== null) ? (Math.round((averageValue * Math.pow(10, scorePrecision)).toFixed(scorePrecision)) / Math.pow(10, scorePrecision)) : '\u2012'}</div>
                            <div className={classes.label}>AVERAGE</div>
                        </div>
                    </div>
                    <ToolTip placement="bottom" title={
                        <div style={{ fontSize: fontSize > 0 ? `${fontSize + 5}%` : '55%', textAlign: 'center' }}>
                            {(displayTotal ? (Math.round(((tuneOut / displayTotal * 100) * 100).toFixed(2)) / 100) : '\u2012')}% TUNE OUT
                        </div>
                    }>
                        <div style={{ lineHeight: '18px', fontSize: fontSize > 0 ? `${fontSize + 5}%` : '55%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', letterSpacing: '0.1rem', textTransform: 'uppercase', whiteSpace: 'nowrap' }}>
                            {
                                !includeTuneOut ?
                                    <>
                                        Viewers:&nbsp;<div style={{ color: theme.palette.tuneOut, fontWeight: 'bold', fontSize: fontSize > 0 ? `${fontSize + 81.25}%` : '125%' }}>{displayTotal}</div>
                                    </>
                                    :
                                    <>
                                        TUNE OUT:&nbsp;<div style={{ color: theme.palette.tuneOut, fontWeight: 'bold', fontSize: fontSize > 0 ? `${fontSize + 81.25}%` : '125%' }}>{tuneOut}</div><div style={{ fontWeight: 'bold', fontSize: fontSize > 0 ? `${fontSize + 81.25}%` : '125%' }}>/{displayTotal}</div>
                                    </>
                            }
                        </div>
                    </ToolTip>
                    <StatsLabelButton dataKey={["TUNE OUTS"]} selected={true} activeColor={colors['tuneout'] || defaultColor} unselectedColor={unselectedColor} textColor={tuneOutColor} />
                </div>
            </Typography>
        </div>}
    </>
    )
})

// const SymbolStats = React.memo(({ dataKeys, symbolKey, total, liveValue, averageValue, scorePrecision, selected, onSelected, virtual, currentButtons, colors, unselectedColor, fontSize, includeTuneOut }) => {

//     const theme = useTheme()



//     // const displayTotal = useMemo(() => {
//     //     if (!!virtual && virtual.dataKey === 'button' && virtual.dataValue === 'R') {
//     //         return virtual.condition === '!==' ? (total - allTuneOuts) : tuneOut
//     //     } else {
//     //         return total
//     //     }
//     // }, [virtual, total, tuneOut, allTuneOuts])

//     const isSelected = useMemo(() => dataKeys.reduce((p, c) => p || selected[c][symbolKey], false), [dataKeys, symbolKey, selected])

//     const onSelect = useCallback((e, dataKey) => onSelected(e, dataKey ? [dataKey] : dataKeys, [symbolKey]), [onSelected, dataKeys, symbolKey])

//     return (
//         <div className={classes.statsBox}>
//             <Typography variant="h5" className={classes.statsText}>
//                 <div className={classes.statsSymbolBox}>
//                     <div className={classes.statsTextRow}>
//                         <IconButton style={{ color: `rgba(0, 0, 0, ${isSelected ? '1' : '0.54'})`, fontSize: '300%' }} onClick={onSelect}>{symbolKey}</IconButton>
//                     </div>
//                     <div className={classes.statsTextRow}>
//                         {dataKeys.map(key => {
//                             const tuneOut = (currentButtons[key] && currentButtons[key].reduce((p, c) => p + c.total, 0)) || 0
//                             const allTuneOuts = (currentButtons['all'] && currentButtons['all'].reduce((p, c) => p + c.total, 0)) || 0
//                             const activeColor = colors[key]?._ || colors["default"]._
//                             const textColor = getContrastYIQ(activeColor)
//                             return <div><StatsLabelButton dataKey={key.split(" + ")} selected={selected[key][symbolKey]} onSelect={(e) => onSelect(e, key)} activeColor={activeColor} unselectedColor={unselectedColor} textColor={textColor} /></div>
//                         })}
//                     </div>
//                 </div>
//             </Typography>
//         </div>
//     )
// })