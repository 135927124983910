import React, { useRef, useCallback, useMemo, useState } from 'react';
import StandardModal from './standard/StandardModal';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import MovieIcon from '@mui/icons-material/Movie';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { RiMovie2Fill, RiMickeyFill, RiGameFill } from 'react-icons/ri'
import { FaFootballBall } from 'react-icons/fa'
import { IoMdFootball } from 'react-icons/io'
import { GiBasketballBall } from 'react-icons/gi'
import { SiNetflix, SiWarnerbros, SiDcentertainment, SiAmazon, SiXbox, SiPlaystation, SiEpicgames, SiRiotgames, SiNintendo, SiNintendonetwork, SiNintendoswitch } from 'react-icons/si'

const segmentMapOf = (segments) => {
  return segments.reduce((p, c) => {
    p[c.questionKey] = (c.answers || []).map(a => a.answer);
    return p
  }, { all: ['all'] })
}

const DialModal = React.memo((props) => {
  const { data: { checkInStarted, sessionStartTime, audienceSegments, virtualSegments, videoStartTime, chartVideoStart, chartVideoEnd, chartConfig, symbolOptions, dialChartSegments, dialMode, multiSection, contentLength }, videoEndTime, videoDuration, showVideoTimeFields, hideDialSettings, forProject } = props
  const [segmentsMap, setSegmentsMap] = useState(segmentMapOf(dialChartSegments));
  const playlistLength = multiSection && contentLength?.length;

  const videoStartInput = useRef(chartVideoStart)
  const videoEndInput = useRef(chartVideoEnd)


  const defaultSegmentOptions = useMemo(() => {
    const optionList = [];
    for (const key in segmentsMap) {
      if (key == 'all') optionList.push({ label: 'Total', code: 'all' })
      else {
        for (const item of segmentsMap[key]) {
          optionList.push({ label: `${key}: ${item}`, code: item });
        }
      }
    }
    return optionList;
  }, [segmentsMap])

  const dialChartSegmentsUpdated = useCallback((newVal, newParam) => {
    let changed = newParam.filter(i => i.questionKey);
    setSegmentsMap(segmentMapOf(changed))
  }, [])

  const videoStartReset = useCallback((params) => {
    params.chartVideoStart = videoStartTime
    return params
  }, [videoStartTime])

  const videoEndReset = useCallback((params) => {
    params.chartVideoEnd = videoEndTime
    return params
  }, [videoEndTime])

  const chartStartUpdate = useCallback((newParams, newValue) => {
    videoStartInput.current = newValue
  }, [])

  const chartEndUpdate = useCallback((newParams, newValue) => {
    videoEndInput.current = newValue
  }, [])

  const chartStarValid = useCallback((value) => {
    return !!videoEndInput.current && ![null, undefined].includes(value) && value < videoEndInput.current
  }, [])

  const chartEndValid = useCallback((value) => {
    return Number.isFinite(videoStartInput.current) && ![null, undefined].includes(value) && value > videoStartInput.current
  }, [])

  const videoOffsetValid = useCallback((value) => {
    return (Math.round(videoStartInput.current || 0) + value) >= 0
  }, [])

  const videoNameLabel = useCallback((pos) => playlistLength && `Video ${pos}`, [playlistLength])

  const segmentOptions = useMemo(() => {
    return [...(audienceSegments || []), ...((virtualSegments || []).map(x => ({ ...x, predefined: true })))]
  }, [audienceSegments, virtualSegments])

  const defaultSymbol = useMemo(() => {
    return symbolOptions ? symbolOptions[0]?.emoji : undefined
  }, [symbolOptions])

  const segmentGroupOptions = useMemo(() => {
    return Object.keys(segmentsMap).map(i => ({ code: i, label: `${i === 'all' ? 'Total' : i}` }))
  }, [segmentsMap])

  const videoTimeFields = useMemo(() => {
    return showVideoTimeFields ? [{
      key: 'chartVideoStart',
      label: 'Video Start Time',
      type: 'hms-time',
      conditions: [
        {
          condition: ({ isLiveSession }) => isLiveSession === true,
        }
      ],
      onUpdate: chartStartUpdate,
      customValidator: chartStarValid,
      buttonLabel: 'RESET',
      buttonIcon: RotateLeftIcon,
      buttonAction: videoStartReset
      // disabled: ({ id, videoLength, checkInStarted }) => allowFullEdit ? (!!id && !!checkInStarted) : true
    },
    {
      key: 'chartVideoEnd',
      label: 'Video End Time',
      type: 'hms-time',
      maxValue: videoDuration,
      conditions: [
        {
          condition: ({ isLiveSession }) => isLiveSession === true,
        }
      ],
      onUpdate: chartEndUpdate,
      customValidator: chartEndValid,
      buttonLabel: 'RESET',
      buttonIcon: RotateLeftIcon,
      buttonAction: videoEndReset
      // disabled: ({ id, videoLength, checkInStarted }) => allowFullEdit ? (!!id && !!checkInStarted) : true
    }, {
      key: 'chartVideoOffset',
      label: 'Video Offset',
      type: 'hms-time',
      minValue: -1 * videoDuration,
      maxValue: videoDuration,
      allowNegative: true,
      conditions: [
        {
          condition: ({ isLiveSession }) => isLiveSession === true,
        }
      ],
      customValidator: videoOffsetValid,
      defaultValue: 0
    }] : []
  }, [showVideoTimeFields, videoStartReset, videoEndReset, chartEndUpdate, chartEndValid, chartStartUpdate, chartStarValid, videoDuration, videoOffsetValid])

  return (
    <StandardModal
      modalKey='DialModal'
      open
      {...props}
      title={`Edit ${forProject ? 'Project' : 'Session'}`}
      titleKey='id'
      buttonLabel='Submit'
      tabs={[
        {
          key: 'segmentsTab',
          label: 'Chart Settings',
          fields: [
            // {
            //   key: 'chartConfig.defaultChartType',
            //   label: 'Default Chart Type',
            //   type: 'select',
            //   options: [
            //     { label: 'Line', code: 'line' },
            //     { label: 'Stacked Bar', code: 'bar' },
            //     { label: '100% Stacked Bar', code: 'bar100' },
            //     { label: 'Area', code: 'area' },
            //     { label: '100% Stacked Area', code: 'area100' }
            //   ],
            //   optionLabel: 'label',
            //   optionValueKey: 'code',
            //   defaultValue: 'line',
            //   conditions: [
            //     {
            //       condition: ({ dialMode }) => dialMode === 'symbol',
            //       required: true
            //     }
            //   ],
            // },
            {
              key: 'chartConfig.segmentationMode',
              label: 'Segmentation Mode',
              type: 'select',
              options: [
                { code: 'byEmoji', label: "By Emoji" },
                { code: 'bySegment', label: "By Segment" }
              ],
              optionLabel: 'label',
              optionValueKey: 'code',
              defaultValue: 'byEmoji',
              conditions: [
                {
                  condition: ({ dialMode }) => dialMode === 'symbol',
                  required: true
                }
              ],
            },
            {
              key: 'chartConfig.defaultSegment',
              label: 'Default Segment',
              type: 'select',
              options: defaultSegmentOptions,
              defaultValue: 'all',
              optionLabel: 'label',
              optionValueKey: 'code',
              conditions: [
                {
                  condition: ({ dialMode }) => dialMode === 'symbol',
                  required: true
                }
              ],
            },
            {
              key: 'chartConfig.defaultEmoji',
              label: 'Default Emoji',
              type: 'select',
              options: symbolOptions,
              defaultValue: defaultSymbol,
              optionLabel: 'tooltip',
              optionValueKey: 'emoji',
              conditions: [
                {
                  condition: ({ symbolOptions, dialMode }) => symbolOptions && dialMode === 'symbol',
                  required: true
                }
              ],
            },
            {
              key: 'chartConfig.defaultEmojiSegmentGroup',
              label: 'Default Emoji Segment Group',
              type: 'select',
              options: segmentGroupOptions,
              optionLabel: 'label',
              optionValueKey: 'code',
              defaultValue: 'all',
              conditions: [
                {
                  condition: ({ dialMode }) => dialMode === 'symbol',
                  required: true
                }
              ],
            },
            {
              key: 'chartConfig.defaultAggregationInterval',
              label: 'Aggregation Interval',
              suffix: 'seconds',
              type: 'select',
              options: [1, 15, 30, 45, 60, 120, 300],
              defaultValue: 30,
              conditions: [
                {
                  condition: ({ dialMode }) => dialMode === 'symbol',
                  required: true
                }
              ],
            },
            {
              key: 'dialChartSegments',
              label: 'Chart Segments',
              type: 'chart-segment-builder',
              forChart: true,
              onUpdate: dialChartSegmentsUpdated,
              options: segmentOptions,
              virtualSegments: [
                {
                  questionKey: 'Session ID',
                  dataKey: 'sessionId',
                  condition: 'split'
                },
                {
                  questionKey: 'Tune Out',
                  dataKey: 'button',
                  dataValue: 'R',
                  condition: '==='
                },
                {
                  questionKey: 'No Tune Out',
                  dataKey: 'button',
                  dataValue: 'R',
                  condition: '!=='
                },
                {
                  questionKey: 'Caucasian',
                  dataKey: 'Ethnicity',
                  dataValue: 'Caucasian or White',
                  condition: '==='
                },
                {
                  questionKey: 'Non Caucasian',
                  dataKey: 'Ethnicity',
                  dataValue: 'Caucasian or White',
                  condition: '!=='
                },
                {
                  questionKey: 'African-American',
                  dataKey: 'Ethnicity',
                  dataValue: 'African-American or Black',
                  condition: '==='
                },
                {
                  questionKey: 'Non African-American',
                  dataKey: 'Ethnicity',
                  dataValue: 'African-American or Black',
                  condition: '!=='
                },
                {
                  questionKey: 'AA & Hisp',
                  dataKey: 'Ethnicity',
                  dataValue: ['African-American or Black', 'Hispanic or Latino'],
                  condition: '==='
                },
                {
                  questionKey: 'Asian',
                  dataKey: 'Ethnicity',
                  dataValue: 'Asian or Pacific Islander',
                  condition: '==='
                },
                {
                  questionKey: 'Scored >= 850',
                  dataKey: 'score',
                  dataValue: 850,
                  condition: '>='
                },
                {
                  questionKey: 'Scored <= 150',
                  dataKey: 'score',
                  dataValue: 150,
                  condition: '<='
                }
              ],
              keyLabel: ''
            }
          ]
        },
        {
          key: 'videoSettingsTab',
          label: 'Video Settings',
          fields: [
            {
              key: 'internalVideoName',
              label: 'Internal Video Name',
              placeholder: 'Enter Internal Name',
              sublabel: videoNameLabel,
              type: 'text-builder',
              staticLength: playlistLength || 1,
              // options: bcVideos,
              // onUpdate: onNewPlaylistUpdate
            },
            ...videoTimeFields,
            {
              key: 'videoMarkers',
              label: 'Video Markers',
              type: 'marker-builder',
              defaultValue: [],
              playlistLength
              // required: true
            },
          ]
        },
        ...(dialMode === 'score' ? [{
          key: 'dialTab',
          label: 'Feedback Settings',
          disabled: hideDialSettings,
          fields: [
            {
              key: 'scoreBase',
              label: 'Max Score',
              type: 'number',
              minValue: 10,
              maxValue: 1000,
              disabled: !!checkInStarted || !!sessionStartTime,
              conditions: [
                {
                  condition: ({ isDialSession }) => isDialSession === true,
                  required: true
                }
              ],
              defaultValue: 100
            },
            {
              key: 'dialStyle',
              label: 'Knob Style',
              type: 'select',
              options: [
                'Chicken Head (ASI)',
                'Flat Faced',
                'Embedded Slider'
              ],
              multiple: false,
              disabled: !!checkInStarted || !!sessionStartTime,
              conditions: [
                {
                  condition: ({ isDialSession }) => isDialSession === true,
                  required: true
                }
              ],
              defaultValue: 'Embedded Slider'
            },
            {
              key: 'sliderKnob',
              label: 'Slider Knob',
              type: 'select',
              options: [
                'Clapperboard',
                'Film Reel',
                'Netflix',
                // 'Hulu',
                'Warner Bros',
                'Amazon',
                'DC',
                'Mickey Ears',
                'Game Controller',
                'Pacman',
                'Xbox',
                'Playstation',
                'Nintendo',
                'Nintendo Switch',
                'Nintendo Network',
                'Epic Games',
                'Riot Games',
                'Football',
                'Basketball',
                'Soccer Ball'
              ],
              multiple: false,
              disabled: !!checkInStarted || !!sessionStartTime,
              conditions: [
                {
                  condition: ({ isDialSession }) => isDialSession === true,
                  required: true
                },
                {
                  condition: ({ dialStyle }) => dialStyle === 'Embedded Slider',
                  required: true
                }
              ],
              defaultValue: 'Movie Icon',
              displayComponent: {
                'Clapperboard': MovieIcon,
                'Film Reel': RiMovie2Fill,
                'Netflix': SiNetflix,
                'Warner Bros': SiWarnerbros,
                'Amazon': SiAmazon,
                'DC': SiDcentertainment,
                'Mickey Ears': RiMickeyFill,
                'Game Controller': SportsEsportsIcon,
                'Pacman': RiGameFill,
                'Xbox': SiXbox,
                'Playstation': SiPlaystation,
                'Nintendo': SiNintendo,
                'Nintendo Switch': SiNintendoswitch,
                'Nintendo Network': SiNintendonetwork,
                'Epic Games': SiEpicgames,
                'Riot Games': SiRiotgames,
                'Football': FaFootballBall,
                'Basketball': GiBasketballBall,
                'Soccer Ball': IoMdFootball
              }
            },
            {
              key: 'sliderKnobColor',
              label: 'Knob Color',
              type: 'color',
              disabled: !!checkInStarted || !!sessionStartTime,
              conditions: [
                {
                  condition: ({ isDialSession }) => isDialSession === true,
                  required: true
                },
                {
                  condition: ({ dialStyle }) => dialStyle === 'Embedded Slider',
                  required: true
                }
              ],
              defaultValue: '#000000'
            },
            {
              key: 'gaugeStyle',
              label: 'Gauge Style',
              type: 'select',
              options: [
                'With Shapes',
                'Without Shapes'
              ],
              multiple: false,
              disabled: !!checkInStarted || !!sessionStartTime,
              conditions: [
                {
                  condition: ({ isDialSession }) => isDialSession === true,
                  required: true
                },
                {
                  condition: ({ dialStyle }) => dialStyle !== 'Embedded Slider',
                  required: true
                }
              ],
              defaultValue: 'With Shapes'
            },
            {
              key: 'dialSnap',
              label: 'Stop at Shapes',
              type: 'boolean',
              disabled: !!checkInStarted || !!sessionStartTime,
              conditions: [
                {
                  condition: ({ isDialSession }) => isDialSession === true,
                },
                {
                  condition: ({ dialStyle }) => dialStyle !== 'Embedded Slider',
                  required: true
                },
                {
                  condition: ({ gaugeStyle }) => gaugeStyle === 'With Shapes',
                }
              ],
              defaultValue: false
            },
            {
              key: 'dialMotion',
              label: 'Motion Response',
              type: 'select',
              disabled: !!checkInStarted || !!sessionStartTime,
              options: [
                'Stepwise',
                'Fluid'
              ],
              multiple: false,
              conditions: [
                {
                  condition: ({ isDialSession }) => isDialSession === true,
                  required: true
                }
              ],
              defaultValue: 'Fluid'
            },
            {
              key: 'numberOfMeters',
              label: 'Number of Steps',
              type: 'number',
              minValue: 0,
              maxValue: 36,
              disabled: !!checkInStarted || !!sessionStartTime,
              conditions: [
                {
                  condition: ({ isDialSession }) => isDialSession === true,
                  required: true
                }
              ],
              defaultValue: 19
            },
            {
              key: 'includeTuneOut',
              label: 'Include Tune Out Button',
              type: 'boolean',
              disabled: !!checkInStarted || !!sessionStartTime,
              conditions: [
                {
                  condition: ({ isDialSession }) => isDialSession === true,
                }
              ],
              defaultValue: true
            },
          ]
        }] : []),
      ]}
      fields={[]}
    />
  );
})

export default DialModal;
