import React, { useRef, useState, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { useRecoilState } from 'recoil';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { currentUser } from '../recoil/atoms'
import queryString from 'query-string';
import { useLocalStorage } from '../hooks/hooks'
import { AppBar, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/material/styles';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import TheatersIcon from '@mui/icons-material/Theaters';
import ChatIcon from '@mui/icons-material/Chat';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MTableActionLoading from '../components/standard/MTableActionLoading';
import MaterialTable, { MTableActions } from "@material-table/core"
import ClientPortalTableDetails from '../components/ClientPortalTableDetails ';
import { getProjectDataPromise, getZoomMeetingDetailsPromise } from '../services/sessionsService'
import { SessionFiles } from '../SessionFiles';
import seasiLogo from '../resources/logo.png';
import config from '../config.json';
import moment from 'moment'

const PREFIX = 'ClientPortal';

const classes = {
  app: `${PREFIX}-app`,
  transition: `${PREFIX}-transition`,
  body: `${PREFIX}-body`,
  footer: `${PREFIX}-footer`,
  page: `${PREFIX}-page`,
  sessions: `${PREFIX}-sessions`,
  toolbar: `${PREFIX}-toolbar`,
  title: `${PREFIX}-title`,
  image: `${PREFIX}-image`,
  actionRow: `${PREFIX}-actionRow`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.app}`]: {
    height: '100%',
    width: '100%',
    minHeight: '100vh',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    // overflow: 'hidden'
  },

  [`& .${classes.transition}`]: {
    position: 'relative',
    width: 'auto',
    height: '100vh',
    overflow: 'hidden'
  },

  [`& .${classes.body}`]: {
    position: 'relative',
    width: 'auto',
    height: '100%',
    // padding: '1rem',
    overflowY: 'auto',
    overflowX: 'hidden'
  },

  [`& .${classes.footer}`]: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 'calc(2rem + 56px)',
  },

  [`& .${classes.page}`]: {
    padding: '1em'
  },

  [`& .${classes.sessions}`]: {
    marginBottom: '1rem',
    width: '100%'
  },

  [`& .${classes.toolbar}`]: {
    width: 'auto',
    height: 'calc(5rem + 5px)',
    backgroundColor: "#343A40"
  },

  [`& .${classes.title}`]: {
    // display: 'flex',
    // alignItems: 'center',
    // flexGrow: '1',
    padding: '0 1em 0 1em'
  },

  [`& .${classes.image}`]: {
    height: 'auto',
    width: 'auto',
    marginLeft: 'auto',
    marginRight: '25px',
    maxWidth: '200px'
  },

  [`& .${classes.actionRow}`]: {
    '& div': {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  }
}));

const Navbar = () => {


  return (
    (<Root>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <Typography variant="h5" noWrap className={classes.title}>
            Client Project Portal
          </Typography>
          {seasiLogo && <img src={seasiLogo} className={classes.image} />}
        </Toolbar>
      </AppBar>
    </Root>)
  );
}

const ClientPortal = React.memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const {projectId} = useParams();
  const search = location.search;

  const [user, setCurrentUser] = useRecoilState(currentUser)
  const [theme, setCurrentTheme] = useState('seasi')
  const [project, setProject] = useState()
  const [sessions, setSessions] = useState()
  const [tableSessions, setTableSessions] = useState(undefined)
  const [loading, setLoading] = useState(false);
  const [tableSort, setTableSort] = useLocalStorage(`clientprojecttablesort`, {})
  const [showSessionFilesId, setShowSessionFilesId] = useState(null);
  const [loadingZoomIds, setLoadingZoomIds] = useState(new Set());

  const tableRef = useRef()

  const { id } = queryString.parse(search);

  const sessionTime = (time) => {
    const localTimezone = moment.tz.guess()
    return moment.tz(time, localTimezone).format('MMMM Do YYYY, h:mm a z')
  }

  useEffect(() => {
    getProjectDataPromise(projectId, id).then((data) => {
      // setCurrentTheme(Object.keys(themes).includes(session.team) ? session.team : 'seasi')
      const client = {
        firstName: data.clientData.firstName,
        lastName: data.clientData.lastName,
        chimeUsername: data.clientData.chimeUsername,
        email: data.clientData.email,
        role: data.clientData.role,
        chatState: data.chatState,
        cameFromClientPortal: true,
        token: id,
        zoomRegistrantIds: data.clientData.zoomRegistrantIds
      }
      setCurrentUser(client)
      setProject(data.project)
      setSessions(data.sessions)
      // setLoaded({ isLoaded: true })
    }).catch((e) => {
      console.log(e)
      const error = e.error || 'Link Expired/Unauthorized'
      // setLoaded({ isLoaded: true, error })
    })
  }, [id, projectId, setCurrentUser])

  const getZoomDetails = useCallback(async (meetingId, registrantId) => {
    const token = id;
    const data = await getZoomMeetingDetailsPromise(meetingId, registrantId, token);
    return data;
  }, [id])

  const openSessionFileModal = useCallback((event, row) => {
    setShowSessionFilesId(row.id);
  }, [])

  const handleCloseSessionFiles = useCallback(() => {
    setShowSessionFilesId(null);
  }, [])

  const goToSession = useCallback((event, row) => {
    navigate(`/client/${row.encryptedId}?id=${id}`, {state: { sessionId: row.id, zoomMeetingId: row.zoomMeetingId, pid: project.encryptedProjectId, user }})
  }, [navigate, id, project, user])

  const goToTheater = useCallback((e, r) => {
    let url = `https://${config.vsrUrl}/session/${r.encryptedId}?id=${id}&cid=${r.id}&pid=${project.encryptedProjectId}`;
    console.log('going to: ', url);
    window.open(url, '_self')
  }, [project, id])


  const joinZoomMeeting = useCallback(async (e, row) => {
    setLoadingZoomIds(prev => new Set([...prev, row.id]))
    const data = await getZoomDetails(row.zoomMeetingId, user?.zoomRegistrantIds?.[row.id]);
    window.open(data?.registration?.join_url, '_blank');
    setLoadingZoomIds(prev => {
      const data = new Set([...prev])
      data.delete(row.id)
      return data
    });
  }, [user]);

  const openWithChat = useCallback((e, row) => {
    if (row.isDialSession) {
      // open dial session with chat
      navigate(`/client/${row.encryptedId}?id=${id}`, {state: { sessionId: row.id, zoomMeetingId: row.zoomMeetingId, pid: project.encryptedProjectId, openWithChat: true, user }})
    } else {
      // open virtual theater with chat
      const url = `https://${config.vsrUrl}/session/${row.encryptedId}?id=${id}&pid=${project.encryptedProjectId}&cid=${row.id}&openchat=true`;
      window.open(url, '_self')
    }
  }, [project, id, user])

  return (
    <>
      {showSessionFilesId ? <SessionFiles sessionId={showSessionFilesId} clientToken={id} cameFromClientPortal={true} handleClose={handleCloseSessionFiles} open={showSessionFilesId} /> : <></>}
      <div className={classes.app}>
        <Navbar />
        <div className={classes.body}>
          <div className={classes.page}>
            <div className={classes.sessions}>
              <MaterialTable
                key={`dialsessionstable`}
                tableRef={tableRef}
                onOrderChange={(i, order) => setTableSort({
                  [i]: order
                })}
                // onFilterChange={(e) => setTableFilters(prev => {
                //   return { ...prev, [filter]: e[0].value }
                // })}
                options={{
                  emptyRowsWhenPaging: false,
                  actionsColumnIndex: -1,
                  detailPanelType: 'single',
                  pageSize: 20
                }}
                columns={[
                  { title: "Session Name", field: "sessionName", defaultSort: tableSort[0] },
                  { title: "Scheduled Date/Time", field: "scheduledStartTime", type: "datetime", defaultSort: Object.keys(tableSort).length ? tableSort[1] : 'desc', render: row => <span>{sessionTime(row.scheduledStartTime)}</span> },
                  { title: "Video Title", field: "videoTitle", defaultSort: tableSort[2] },
                  { title: "Session Start Time", field: "sessionStartTime", type: "datetime", defaultSort: tableSort[3], render: row => <span>{row.sessionStartTime ? sessionTime(row.sessionStartTime) : 'N/A'}</span> }
                ]}
                isLoading={loading || !sessions}
                data={sessions || []} //MATERIAL TABLE MUTATES PROPS :(
                title="Sessions"
                actions={[
                  (rowData) => {
                    return {
                      icon: VideocamOutlinedIcon,
                      tooltip: 'Join Meeting',
                      onClick: joinZoomMeeting,
                      hidden: !rowData.zoomMeetingId,
                      isLoading: loadingZoomIds.has(rowData.id)
                    }
                  },
                  {
                    icon: FileCopyIcon,
                    tooltip: 'Session Files',
                    onClick: openSessionFileModal
                  },
                  (rowData) => {
                    return {
                      icon: ChatIcon,
                      tooltip: 'Open with Chat',
                      onClick: openWithChat,
                      disabled: !rowData.chimeChatInstance
                    }
                  },
                  (rowData) => {
                    return {
                      icon: ShowChartIcon,
                      tooltip: 'Live Feedback Console',
                      onClick: goToSession,
                      hidden: !(rowData.isDialSession)
                    }
                  },
                  (rowData) => {
                    return {
                      icon: TheatersIcon,
                      tooltip: 'Virtual Theater',
                      onClick: goToTheater,
                      hidden: (rowData.isDialSession)
                    }
                  }
                ]
                }
                components={{
                  Action: props => {
                    return (
                      <MTableActionLoading {...props} />
                    )
                  },
                  Actions: props => {
                    return (
                      <div className={classes.actionRow}><MTableActions {...props} /></div>
                    )
                  },
                }}
                detailPanel={[{
                  render: ({ rowData }) => <ClientPortalTableDetails getZoomDetails={getZoomDetails} zoomRegistrantId={user?.zoomRegistrantIds?.[rowData.id]} {...rowData} />
                }]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

export default ClientPortal;