import React, { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { CompactPicker } from 'react-color'
import { makeStyles } from '@mui/material/styles';

const PREFIX = 'ColorInputField';

const classes = {
  root: `${PREFIX}-root`,
  label: `${PREFIX}-label`,
  textField: `${PREFIX}-textField`
};

const Root = styled('div')({
  //border: 'solid 2px green',
  display: 'flex',
  padding: '5px',
  alignItems: 'center',
  width: '100%',
  [`& .${classes.label}`]: {
    flexBasis: '20%',
    // minWidth: '15%',
    textAlign: 'right',
    marginRight: '10px'
  },
  [`& .${classes.textField}`]: {
    flexBasis: '80%'
    // width: '100%'
  }
});

const ColorInputField = React.memo(({ value, label, disabled, placeholder, invalid, onChange, ...colorProps }) => {


  const handleChange = useCallback((color) => {
    onChange(null, (color && color.hex) || null)
  }, [onChange]);

  return (
    <Root>
      <Typography className={classes.label}>{label}</Typography>
      <div style={{ filter: disabled ? 'grayscale(0.75)' : 'none', border: `1px solid ${invalid ? '#f44336' : 'transparent'}`, pointerEvents: disabled ? 'none' : 'auto' }}>
        <CompactPicker className={classes.colorField} color={value || {}} onChangeComplete={handleChange} {...colorProps} />
      </div>
    </Root>
  );
})

ColorInputField.defaultProps = {
  label: 'color'
}

export default ColorInputField;
