import { atom, atomFamily } from 'recoil';

export const sessionState = atom({
    key: 'sessionState',
    default: null
})

export const currentUser = atom({
    key: 'currentUser',
    default: null
})

export const selectedSession = atom({
    key: 'selectedSession',
    default: null
})

export const projectState = atom({
    key: 'projectState',
    default: null
})

export const showSessionChat = atomFamily({
    key: 'showSessionChat',
    default: false,
});

export const guestsForSession = atomFamily({
    key: 'guestsForSession',
    default: null,
  });

  export const guestConnections = atomFamily({
    key: 'guestConnections',
    default: null,
  });