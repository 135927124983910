import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useParams} from 'react-router-dom';
import { currentUser } from './recoil/atoms'
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { useOktaAuth } from '@okta/okta-react';
import LoadingPage from './pages/LoadingPage'
import AppMain from './AppMain';

// const chartColors = [
//   '#003f5c',
//   '#2f4b7c',
//   '#665191',
//   '#a05195',
//   '#d45087',
//   '#f95d6a',
//   '#ff7c43',
//   '#ffa600'
// ]

const themes = {
  seasi: responsiveFontSizes(createTheme({
    palette: {
      primary: {
        main: '#7A3493' //'#454f8c'
      },
      secondary: {
        main: '#ED254E' //'#B80C09'
      },
      chat: {
        main: '#707BBB'
      },
      toolbar: '#343A40',
      segments: {
        all: '#BA274A',//'#92374D',
        default: '#17768D',
        unselected: '#888888',
        Male: '#4D9DE0', //'#8594ca', '#deacae', '#bda175'
        Female: '#FF7B9C', //'#8594ca', '#deacae', '#bda175'
        Other: '#E1BC29' //'#8594ca', '#deacae', '#bda175'
      },
      dial: '#7C9EB2', //677BA8
      selectedDial: '#17768D',//'#008bf9',
      tuneIn: '#3BB273',//'#58BC82',
      tuneOut: '#E15554',//'#DB504A',
    },
    overrides: {
      MUIRichTextEditor: {
        root: {
          width: "100%",
          border: "1px solid #bdbdbd",
          borderRadius: '4px',
          boxSizing: 'border-box'
        },
        editorContainer: {
          padding: '0.6rem',
          margin: 0,
          boxSizing: 'border-box'
        },
        placeHolder: {
          position: 'relative'
        },
        toolbar: {
          display: 'flex',
          justifyContent: 'space-around',
          marginBottom: '0.5rem',
          paddingBottom: '0.5rem',
          borderBottom: '1px solid #e0e0e0'
        }
      }
    }
  })),
  pfm: responsiveFontSizes(createTheme({
    palette: {
      primary: {
        main: '#6C54A4'
      },
      secondary: {
        main: '#EE6352'
      },
      chat: {
        main: '#707BBB'
      }
      // all: '#BA274A',//'#92374D',
      // male: '#4D9DE0', //'#8594ca', '#deacae', '#bda175'
      // female: '#FF7B9C', //'#8594ca', '#deacae', '#bda175'
      // other: '#E1BC29',
      // genders: {
      //     male: '#4D9DE0', //'#8594ca', '#deacae', '#bda175'
      //     female: '#FF7B9C', //'#8594ca', '#deacae', '#bda175'
      //     other: '#E1BC29' //'#8594ca', '#deacae', '#bda175'
      // },
      // respondents: '#17768D',
      // dial: '#7C9EB2', //677BA8
      // selectedDial: '#17768D',//'#008bf9',
      // tuneIn: '#3BB273',//'#58BC82',
      // tuneOut: '#E15554',//'#DB504A'
    },
  })),
  vvtv: responsiveFontSizes(createTheme({
    palette: {
      primary: {
        main: '#be8b1f'
      },
      secondary: {
        main: '#B91372'//'#D34F73'
      },
      chat: {
        main: '#707BBB'
      }
    },
  })),
  ew: responsiveFontSizes(createTheme({
    palette: {
      primary: {
        main: '#0099ff'
      },
      secondary: {
        main: '#D64045' //'#E26D5C'
      },
      chat: {
        main: '#707BBB'
      },
    },
  })),
  cpt: responsiveFontSizes(createTheme({
    palette: {
      // primary: {
      //   main: '#6C54A4'
      // }
    },
  })),
};

// const validTeams = [
//   'seasi', 'pfm', 'vvtv', 'ew', 'cpt'
// ]

const fullAccess = [
  "session",
  "audience",
  "tc",
  "balance",
  "client",
  "dial",
  "video"
]

const theme = "seasi"

const App = React.memo(({ ...props }) => {
  const {sessionId, projectId} = useParams();
  console.log('render app', props)
  // const [theme, setCurrentTheme] = useState('seasi')
  // const setCurrentUser = useSetRecoilState(currentUser)
  const [user, setCurrentUser] = useRecoilState(currentUser)
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    oktaAuth.getUser().then((user) => {
      console.log('got current user: ', user)
      if (!user) throw new Error('UNDEFINED USER')
      // if (!user.groups) throw new Error('UNDEFINED GROUPS')
      if ((!user.accessLevels || !user.accessLevels.length) && !user.fullAdmin) throw new Error('NO ACCESS LEVELS DEFINED')
      if (user.fullAdmin) user.accessLevels = fullAccess
      user.chimeUsername = `${user.given_name} (Staff)`
      // let group = user.groups[0].toLocaleLowerCase().replace(/livetrac|( ?- ?)/gi, '')
      // if (!validGroups.includes(group)) throw 'INVALID GROUP'
      // const admin = /admin/gi.test(team);
      // user.team = team.replace(/( ?admin ?)/gi, '')
      // user.admin = admin
      // setCurrentTheme(Object.keys(themes).includes(team) ? team : 'seasi')
      setCurrentUser(user)
    }).catch((e) => {
      console.log(e)
      oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
    })
  }, [oktaAuth, setCurrentUser])

  console.log('sessionid:', sessionId)
  console.log('projectId:', projectId)
  console.log('themes[theme]:', themes[theme])

  return (theme && user) ? <ThemeProvider theme={themes[theme]}><AppMain sessionId={sessionId} projectId={projectId} {...props} /></ThemeProvider> : <LoadingPage />;
})

export default App;
