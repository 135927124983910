import React, { useCallback, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/material/styles';
import StandardField from './StandardField';
import { useMemoCompare } from '../../../hooks/hooks'

const PREFIX = 'PaymentTypeSelectorField';

const classes = {
  root: `${PREFIX}-root`
};

const Root = styled('div')({
  width: '100%',
  // display: 'flex',
  // flexDirection: 'row',
  // padding: '6px',
  // margin: '0 8px 0 8px',
  // label: {
  //   display: 'flex',
  //   justifyContent: 'flex-end',
  //   alignItems: 'center',
  //   flexBasis: '20%',
  //   // textAlign: 'right',
  //   marginRight: '10px !important',
  //   padding: '5px',
  // },
});

const isEqual = require('lodash/isEqual');
const cloneDeep = require('lodash/cloneDeep');

const PaymentTypeSelectorField = React.memo(({ value = {}, denominations, denominationLabel, denominationValueKey, label, fieldKey, required, conditional, conditionMet, onChange, invalid, invalidate, ...restProps }) => {

  const valueRef = useRef();

  valueRef.current = value || {}

  // const channelNames = useMemoCompare(value ? value.map(x => x.displayName) : [], isEqual);

  const handlePaymentTypeChange = useCallback((newValue, valueKey, key) => {
    const newPaymentTypes = cloneDeep(valueRef.current)
    newPaymentTypes[key] = newValue?.[valueKey]
    console.log('payment type change!', newValue, key, newPaymentTypes)
    onChange(null, newPaymentTypes)
  }, [onChange])

  return (
    <Root>
      {label && <Typography className={classes.label}>{label}</Typography>}
      {denominations && denominations.map((denomination, i) =>
        <React.Fragment key={`payment-type-fragment${i}`}>
          <StandardField
            type='select'
            fieldKey={`${fieldKey}-payment-type${i}`}
            key={`${fieldKey}-payment-type${i}`}
            label={(!!denomination && typeof denomination === 'object') ? typeof denominationLabel === 'function' ? denominationLabel(denomination) : denomination[denominationLabel] : denomination}
            value={value?.[(!!denomination && typeof denomination === 'object') ? denomination[denominationValueKey] : denomination]}
            displayComponent={(option) => () => <span>{option.currencyCode}</span>}
            required={true}
            // required={required || (conditional && conditionMet)}
            onChange={(e, v, k) => handlePaymentTypeChange(v, k, (!!denomination && typeof denomination === 'object') ? denomination[denominationValueKey] : denomination)}
            invalidate={invalidate}
            {...restProps}
          />
        </React.Fragment>
      )}
    </Root>
  );
})

// MultiChatChannelField.defaultProps = {

// }

export default PaymentTypeSelectorField;
